<template>
  <calendar :exam-centers="[examCenter]" />
</template>

<script>
import Calendar from '@/components/exam-centers/Calendar';

export default {
  name: 'ExamCenterCalendar',
  components: { Calendar },
  props: {
    examCenter: {
      type: Object,
      required: true,
    },
  },
};
</script>
