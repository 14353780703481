<template>
  <fragment>
    <e-toolbar>
      <portal-target name="examCenterSubmit" />
      <e-button
        v-if="value.id"
        variant="tertiary"
        small
        data-test="create-room-submit"
        @click="openCreateRoom"
      >
        <e-icon icon="mdi-plus" class="mr-2" />
        Raum hinzufügen
      </e-button>
    </e-toolbar>
    <exam-center-form
      v-if="!isLoading"
      data-test="create-exam-center-form"
      :value="value"
      @submit="updateExamCenter"
    />
    <room-table
      :exam-center="value"
      @update-room="goToRoomUpdate"
      @delete-room="goToRoomDelete"
      @create-room="goToRoomCreate"
    />
    <exam-center-contact-data
      :exam-center="value"
      @set-contact-primary="setContactPrimary"
      @set-address-type="setAddressType"
    />
    <form-modal
      :title="$route.meta.modalTitle"
      :is-open="$route.meta.showFormModal"
      :is-loading="isLoading"
      :error-messages="errorMessages"
      @close="closeModal"
    >
      <router-view
        @update-room="updateRoom"
        @create-room="createRoom"
        @create-contact-person="createContactPerson"
        @update-contact-person="updateContactPerson"
        @create-address="createAddress"
        @update-address="updateAddress"
      />
    </form-modal>
    <router-view
      :is-open="$route.meta.showDeleteModal"
      name="deletion-modal"
      @delete-success="closeAndRefresh"
      @close="closeModal"
    />
  </fragment>
</template>

<script>
import { examCenters, examCenters as examCenterApi } from '@/api/ExamCenters';
import ExamCenterForm from '../../components/exam-centers/ExamCenterForm';
import RoomTable from '../../components/exam-centers/RoomTable';
import ExamCenterContactData from '../../components/exam-centers/ExamCenterContactData';
import FormModal from '@/components/common/FormModal';
import { Api } from '@/api';
import { updateSnackbar } from '@/store/modules/snackbar';

export default {
  name: 'ExamCenterDetails',
  components: {
    FormModal,
    ExamCenterContactData,
    ExamCenterForm,
    RoomTable,
  },
  data() {
    return {
      value: {
        rooms: [],
        addresses: [],
        contactPersons: [],
      },
      isLoading: false,
      errorMessages: [],
    };
  },
  async created() {
    this.isLoading = true;
    const examCenterId = this.$route.params.examCenterId;
    const { data } = await examCenters.getById(examCenterId);
    this.value = data;
    this.isLoading = false;
    this.$emit('set-view-subheadline', this.value.name);
  },
  methods: {
    goToRoomUpdate(room) {
      this.$router.push({
        name: 'exam-center-room-update',
        params: {
          examCenterId: this.value.id,
          roomId: room.id,
          room,
          isLoading: this.isLoading,
        },
      });
    },
    goToRoomCreate() {
      this.$router.push({
        name: 'exam-center-room-create',
        params: {
          examCenterId: this.value.id,
          examCenter: this.value,
        },
      });
    },
    goToRoomDelete(room) {
      this.$router.push({
        name: 'exam-center-room-delete',
        params: {
          examCenter: this.value,
          examCenterId: this.value.id,
          roomId: room.id,
          room,
        },
      });
    },
    openCreateRoom() {
      this.$router.push({
        name: 'exam-center-room-create',
        params: {
          examCenter: this.value,
        },
      });
    },
    closeModal() {
      this.errorMessages = [];
      this.$router.push({
        name: 'exam-center',
        params: {
          examCenterId: this.value.id,
          examCenter: this.value,
        },
      });
    },
    async createContactPerson(contactPerson) {
      try {
        await Api.examCenters.contactPersons(this.value.id, contactPerson);
        await this.closeAndRefresh('Contact person has been created.');
      } catch (error) {
        this.handleApiError(error);
      }
    },
    async updateContactPerson(contactPerson) {
      try {
        await Api.contactPersons.update(contactPerson.id, contactPerson);
        await this.closeAndRefresh('Contact person has been updated.');
      } catch (error) {
        this.handleApiError(error);
      }
    },
    async createAddress(address) {
      const getOtherType = (value) =>
        value.type === 'main' ? 'correspondence' : 'main';
      const type = !this.value.addresses.length
        ? 'main'
        : getOtherType(this.value.addresses[0]);

      try {
        await Api.examCenters.addresses(this.value.id, { ...address, type });
        await this.closeAndRefresh('Address has been created.');
      } catch (error) {
        this.handleApiError(error);
      }
    },
    async updateAddress(address) {
      try {
        await Api.addresses.update(address.id, address);
        await this.closeAndRefresh('Address has been updated.');
      } catch (error) {
        this.handleApiError(error);
      }
    },
    async setContactPrimary(contactPerson) {
      await Api.contactPersons.setPrimary(contactPerson.id, contactPerson);
      await this.closeAndRefresh(
        'Contact person has been updated to primary contact.'
      );
    },
    async setAddressType(address, type) {
      await Api.addresses.setType(address.id, address, type);
      await this.closeAndRefresh(`Address has been updated to type ${type}`);
    },
    async createRoom(room) {
      this.isLoading = true;
      try {
        const { data } = await Api.examCenters.rooms(this.value.id, room);
        if (room.timeBlocks) {
          await Promise.all(
            room.timeBlocks.map(async (timeBlock) => {
              await Api.rooms.addTimeblock(data.id, {
                timeBlockId: timeBlock.id,
              });
            })
          );
        }
        await this.closeAndRefresh('Room has been created.');
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateRoom(room) {
      // TODO: https://careerpartner.atlassian.net/browse/EP-4370

      this.isLoading = true;
      try {
        const { data } = await Api.rooms.update(room.id, room);
        const roomOld = this.value.rooms.find((roomOld) => {
          return roomOld.id === room.id;
        });
        const timeBlockIdsOld = roomOld.timeBlocks.map((timeBlock) => {
          return timeBlock.id;
        });
        const timeBlockIdsNew = room.timeBlocks.map((timeBlock) => {
          return timeBlock.id;
        });
        await Promise.all(
          timeBlockIdsOld.map(async (timeBlockId) => {
            if (
              !timeBlockIdsNew.find(
                (timeBlockIdNew) => timeBlockIdNew === timeBlockId
              )
            ) {
              await Api.rooms.removeTimeblock(data.id, timeBlockId);
            }
          })
        );

        await Promise.all(
          timeBlockIdsNew.map(async (timeBlockId) => {
            if (
              !timeBlockIdsOld.find(
                (timeBlockIdOld) => timeBlockIdOld === timeBlockId
              )
            ) {
              await Api.rooms.addTimeblock(data.id, {
                timeBlockId,
              });
            }
          })
        );
        await this.closeAndRefresh('Room has been updated.');
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async closeAndRefresh() {
      this.closeModal();
      const { data } = await Api.examCenters.getById(this.value.id);
      this.value = data;
    },
    async updateExamCenter(examCenter) {
      const payload = {
        name: examCenter.name,
        location: examCenter.location,
        directions: examCenter.directions,
        comment: examCenter.comment,
      };

      const { data } = await examCenterApi.updateById(examCenter.id, payload);

      this.$emit('set-view-subheadline', data.name);

      this.value = data;

      updateSnackbar({ message: 'Exam Center has been updated' });
    },
    handleApiError(error) {
      if (!error.response?.data?.message) {
        throw error;
      }
      this.errorMessages = error.response.data.message;
    },
  },
};
</script>
