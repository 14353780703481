import { ApiService } from '@epos/core-applets';
import { successHandler, errorHandler } from '@/utils/responseHandlers';

ApiService.instance.interceptors.response.use(successHandler, errorHandler);

const BASE_PATH = '/examcenter/v1';

/**
 * @param resourceName
 * @returns {BasicApiResource}
 * @constructor
 */
export const BasicResourceFactory = (resourceName) => {
  const RESOURCE_BASE_PATH = `${BASE_PATH}/${resourceName}`;

  return {
    get: (params) => ApiService.get(RESOURCE_BASE_PATH, { params }),
    post: (payload) => ApiService.post(RESOURCE_BASE_PATH, payload),
    getById: (id) => ApiService.get(`${RESOURCE_BASE_PATH}/${id}`),
    updateById: (id, payload) =>
      ApiService.put(`${RESOURCE_BASE_PATH}/${id}`, payload),
    deleteById: (id) => ApiService.delete(`${RESOURCE_BASE_PATH}/${id}`),
    postSubPathById: (id, path, payload) =>
      ApiService.post(`${RESOURCE_BASE_PATH}/${id}/${path}`, payload),
    putSubPathById: (id, path, payload) =>
      ApiService.put(`${RESOURCE_BASE_PATH}/${id}/${path}`, payload),
    deleteSubPathById: (parentId, id, path) =>
      ApiService.delete(`${RESOURCE_BASE_PATH}/${parentId}/${path}/${id}`),
  };
};

/**
 * @callback AxiosRequest
 * @param {string} url
 * @param {Object=} config
 * @returns {Promise<{data: JSON, status: number, headers: *, request?: *}>}
 */

/**
 * @typedef {Object} BasicApiResource
 * @property {(function(Object): AxiosRequest)} post
 * @property {(function(String): AxiosRequest)} getById
 * @property {(function(Object): AxiosRequest)} get
 * @property {(function(String): AxiosRequest)} deleteById
 * @property {(function(String, Object): AxiosRequest)} updateById
 * @property {(function(String, String, Object): AxiosRequest)} postSubPathById
 * @property {(function(String, String, Object): AxiosRequest)} putSubPathById
 * @property {(function(String, String, String): AxiosRequest)} deleteSubPathById
 */
