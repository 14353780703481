<template>
  <contact-person-form :contact-person="value" @submit="updateContactPerson" />
</template>

<script>
import ContactPersonForm from '@/components/exam-centers/ContactPersonForm';
export default {
  name: 'ContactPersonUpdate',
  components: { ContactPersonForm },
  props: {
    contactPerson: {
      type: Object,
      required: true,
    },
    examCenterId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: { ...this.contactPerson },
    };
  },
  methods: {
    updateContactPerson(contactPerson) {
      this.$emit('update-contact-person', contactPerson);
    },
  },
};
</script>
