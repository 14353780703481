var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center"},[_c('div',{staticClass:"w-full"},[_c('e-calendar',{staticClass:"my-9 w-full",attrs:{"highlighted-dates":_vm.bookedDates,"to-page":_vm.toPage,"value":_vm.selectedDateString},on:{"input":_vm.onCalendarClick,"update:to-page":_vm.updateToPage}})],1),_c('div',{staticClass:"flex w-full flex-col rounded-lg bg-blue-100"},[_c('div',{staticClass:"flex items-center border-b border-gray-400 p-5"},[_c('div',{staticClass:"font-bold text-black"},[_c('p',[_vm._v(" Räume buchen für Prüfung am "+_vm._s(_vm.formatDateToLocale(_vm.selectedDateString))+" ")])])]),_c('div',{staticClass:"flex flex-col p-5 pb-0"},[_c('e-checkbox-group',{attrs:{"items":_vm.roomIds},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onToggle = ref.toggle;
var toggleAll = ref.toggleAll;
return [_c('e-dual-picker',{attrs:{"items":_vm.filteredRooms,"items-search-selected":_vm.searchSelected,"items-search-unselected":_vm.searchItems,"selected-filtered":_vm.selectedFilteredRooms},on:{"toggle":function (value) { return onToggle([value]); },"toggle-all":toggleAll,"update:items-search-unselected":_vm.setSearchUnselected,"update:items-search-selected":_vm.setSearchSelected},scopedSlots:_vm._u([{key:"headlineLeft",fn:function(){return [_c('p',[_vm._v("Alle verfügbaren Räume")])]},proxy:true},{key:"headlineRight",fn:function(){return [_c('p',[_vm._v("Ausgewählte Räume")])]},proxy:true},{key:"emptySelected",fn:function(){return [_c('p',{staticClass:"px-3 py-2 font-bold"},[_vm._v("Kein Raum zugeordnet")])]},proxy:true},{key:"emptyAvailable",fn:function(){return [_c('p',{staticClass:"px-3 py-2 font-bold"},[_vm._v("Keine Räume verfügbar")])]},proxy:true},{key:"unselected",fn:function(ref){
var items = ref.items;
var toggle = ref.toggle;
return _vm._l((items),function(examCenter){return _c('li',{key:examCenter.id},[_c('p',{staticClass:"py-2 pl-4 font-bold"},[_vm._v(_vm._s(examCenter.name))]),_c('ul',_vm._l((examCenter.rooms),function(room){return _c('e-dual-picker-list-item',{key:room.id,nativeOn:{"click":function($event){return toggle(room.id)}}},[_vm._v(" "+_vm._s(room.name)+" ")])}),1)])})}},{key:"selected",fn:function(ref){
var selectedItems = ref.selectedItems;
var toggle = ref.toggle;
return _vm._l((selectedItems),function(group){return _c('li',{key:group.name},[_c('p',{staticClass:"py-2 pl-4 font-bold"},[_vm._v(_vm._s(group.name))]),_c('ul',_vm._l((group.rooms),function(room){return _c('e-dual-picker-list-item',{key:room.id,attrs:{"action":"remove"},nativeOn:{"click":function($event){return toggle(room.id)}}},[_vm._v(" "+_vm._s(room.name)+" ")])}),1)])})}}],null,true)})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"flex justify-end p-5"},[_c('e-button',{staticClass:"mt-18 ml-auto",attrs:{"disabled":_vm.isLoading,"variant":"primary","data-test":"calendar-save"},on:{"click":_vm.submit}},[(_vm.isLoading)?_c('e-icon',{staticClass:"mr-2 animate-spin",attrs:{"icon":"mdi-loading"}}):_vm._e(),_vm._v(" Speichern ")],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }