<template>
  <address-form :address="value" @submit="updateAddress" />
</template>

<script>
import AddressForm from '@/components/exam-centers/AddressForm';
export default {
  name: 'AddressUpdate',
  components: { AddressForm },
  props: {
    address: {
      type: Object,
      required: true,
    },
    examCenterId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: { ...this.address },
    };
  },
  methods: {
    updateAddress(address) {
      this.$emit('update-address', address);
    },
  },
};
</script>
