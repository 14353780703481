var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"epos__room-form col-span-12 mb-16",attrs:{"data-test":"room-form"}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-col gap-x-6 lg:grid lg:grid-cols-12"},[_c('div',{staticClass:"col-span-8 my-3 mt-6"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"room-form__name","data-test":"room-form__name","autofocus":_vm.room.id === null,"errors":errors,"label":"Name","required":""},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-4 my-3 mt-6"},[_c('validation-provider',{attrs:{"name":"Kapazität","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"room-form__capacity","data-test":"room-form__capacity","errors":errors,"label":"Kapazität","required":"","type":"number"},model:{value:(_vm.value.capacity),callback:function ($$v) {_vm.$set(_vm.value, "capacity", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.capacity"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-12 my-3"},[_c('validation-provider',{attrs:{"name":"Timeblocks","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"room-form__timeblocks","data-test":"room-form__timeblocks","errors":errors,"multiple":true,"options":_vm.getTimeBlocksAsOptions,"label":"Zeitblock (Mehrfachauswahl möglich)","option-label":"text","track-by":"id"},model:{value:(_vm.timeBlocks),callback:function ($$v) {_vm.timeBlocks=$$v},expression:"timeBlocks"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-12 my-3"},[_c('validation-provider',{attrs:{"name":"isLight","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-checkbox',{attrs:{"id":"room-form__isLight","data-test":"room-form__isLight","errors":errors,"check-value":"isLight","label":"Light"},model:{value:(_vm.value.isLight),callback:function ($$v) {_vm.$set(_vm.value, "isLight", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.isLight"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-12 my-3"},[_c('p',{staticClass:"text-xs text-gray-700"},[_vm._v("* Pflichtfelder")])])]),_c('portal',{attrs:{"to":"form-modal-submit"},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return _c('e-button',{staticClass:"mt-18 ml-auto",attrs:{"disabled":invalid || !_vm.hasChanged || slotProps.isLoading,"data-test":"modal-confirm","variant":"primary"},on:{"click":function($event){return _vm.$emit('submit', _vm.value)}}},[(!!slotProps.isLoading)?_c('e-icon',{staticClass:"mr-2 animate-spin",attrs:{"icon":"mdi-loading"}}):_vm._e(),_vm._v(" Speichern ")],1)}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }