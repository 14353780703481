import Vue from 'vue';
import Vuex from 'vuex';
import currentUser from '@/store/modules/currentUser';
import errors from '@/store/modules/errors';
import timeBlocks from '@/store/modules/timeBlocks';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    currentUser,
    errors,
    timeBlocks,
  },
});
