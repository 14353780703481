<template>
  <e-menu :id="id" ref="filtersMenu" :actions="[]">
    <template #activator="{ interact }">
      <span class="uppercase">{{ label }}</span>
      <e-button
        :data-test="`${id}__filter-button`"
        class="px-2"
        small
        variant="text"
        @click.stop.native="interact"
      >
        <e-icon :icon="icon" color="primary" size="sm" />
      </e-button>
    </template>
    <template #default>
      <li v-for="option in options" :key="`${option.value}`" class="px-3">
        <e-radio-button
          :id="`${id}--${option.value}`"
          v-model="internalValue"
          :data-test="`${id}--${option.value}`"
          :label="option.label"
          :name="option.label"
          :option-value="option.value"
        />
      </li>
    </template>
  </e-menu>
</template>

<script>
export default {
  name: 'ESimpleTableFilter',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-filter-variant',
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
        this.$refs.filtersMenu.closeMenu();
      },
    },
  },
};
</script>
