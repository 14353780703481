import { extend } from 'vee-validate';
import { required, max, regex, email } from 'vee-validate/dist/rules';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const ALPHA_SEPARATORS_REGEX = /[A-Za-zÀ-ȕ .,-]+$/;
export const IU_DOMAIN = 'iu.org';

extend('required', required);

extend('max', max);

extend('regex', regex);

extend('email', email);

// extend('iu_email', {
//   validate: (value) => email.validate(`${value}@${IU_DOMAIN}`),
// });

extend('phone', {
  validate: (value) => isValidPhoneNumber(value, 'DE'),
});
