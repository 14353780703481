var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"epos__room-form col-span-12 mb-16",attrs:{"data-test":"contact-person-form"}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-col gap-x-6 lg:grid lg:grid-cols-12"},[_c('div',{staticClass:"col-span-6 my-3 mt-6"},[_c('validation-provider',{attrs:{"name":"Vorname","rules":"required|max:100","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"contact-person-form__givenName","data-test":"contact-person-form__givenName","errors":errors,"autofocus":_vm.contactPerson.id === null,"label":"Vorname","required":""},model:{value:(_vm.value.givenName),callback:function ($$v) {_vm.$set(_vm.value, "givenName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.givenName"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-6 my-3 mt-6"},[_c('validation-provider',{attrs:{"name":"Nachname","rules":"required|max:50","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"contact-person-form__familyName","data-test":"contact-person-form__familyName","errors":errors,"label":"Nachname","required":""},model:{value:(_vm.value.familyName),callback:function ($$v) {_vm.$set(_vm.value, "familyName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.familyName"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-12 my-3"},[_c('validation-provider',{attrs:{"name":"E-Mail-Adresse","rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"contact-person-form__email","data-test":"contact-person-form__email","errors":errors,"label":"Email","required":""},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.email"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-12 my-3"},[_c('validation-provider',{attrs:{"name":"Telefonnummer","rules":"required|phone","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"contact-person-form__phone","data-test":"contact-person-form__phone","errors":errors,"label":"Telefonnummer","required":""},model:{value:(_vm.value.phone),callback:function ($$v) {_vm.$set(_vm.value, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.phone"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-12 my-3"},[_c('p',{staticClass:"text-xs text-gray-700"},[_vm._v("* Pflichtfelder")])])]),_c('portal',{attrs:{"to":"form-modal-submit"}},[_c('e-button',{staticClass:"mt-18 ml-auto",attrs:{"variant":"primary","disabled":invalid || !_vm.hasChanged,"data-test":"modal-confirm"},on:{"click":function($event){return _vm.$emit('submit', _vm.value)}}},[_vm._v(" Speichern ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }