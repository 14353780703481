<template>
  <div>
    <contacts-box
      :contacts="addresses"
      :actions="actions"
      :tags="tags"
      section-title="Adresse"
      data-test="exam-center-addresses"
      @click-update="clickUpdate"
      @click-delete="clickDelete"
      @click-set-as-main="setAsMainAddress"
      @click-set-as-correspondence="setAsCorrespondenceAddress"
    >
      <template #data="{ item: address }">
        <span class="truncate">
          {{ address.name }}<br />
          {{ address.street }} {{ address.houseNumber }}<br />
          {{ address.zip }} {{ address.city }}<br />
          {{ getCountryNameByIsoCode(countries, address.countryId) }}
        </span>
      </template>
    </contacts-box>
    <e-button
      variant="text"
      class="mt-4"
      data-test="exam-center-add-address"
      :disabled="addresses.length === 2"
      @click="clickAdd"
    >
      <e-icon icon="mdi-plus" />
      Addresse hinzufügen
    </e-button>
    <p v-if="addresses.length === 2" class="pl-1 text-xs text-gray-700">
      Es können maximal zwei Adressen hinterlegt werden.
    </p>
  </div>
</template>
<script>
import ContactsBox from '@/components/common/ContactsBox';
import {
  getCountries,
  getCountryNameByIsoCode,
} from '@/constants/picklists/countries';
export default {
  name: 'AddressBox',
  components: { ContactsBox },
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      countries: [],
    };
  },
  computed: {
    examCenter() {
      return this.value;
    },
  },
  async created() {
    this.countries = await getCountries();
  },
  methods: {
    getCountryNameByIsoCode,
    tags(address) {
      return address.type === 'main' ? ['Hausanschrift'] : ['Korrespondenz'];
    },
    actions(address) {
      return [
        {
          id: 'click-update',
          text: 'bearbeiten',
          disabled: false,
        },
        {
          id: 'click-delete',
          text: 'löschen',
          disabled: false, // TODO
        },
        {
          id: 'click-set-as-main',
          text: 'als Hausanschrift festlegen',
          disabled: address.type === 'main',
        },
        {
          id: 'click-set-as-correspondence',
          text: 'als Korrespondenzadresse festlegen',
          disabled: address.type === 'correspondence',
        },
      ];
    },
    clickAdd() {
      this.$emit('open-address-create');
    },
    clickUpdate(payload) {
      this.$emit('open-address-update', payload);
    },
    clickDelete(payload) {
      this.$emit('open-address-delete', payload);
    },
    setAsMainAddress(payload) {
      this.$emit('set-as-main-address', payload);
    },
    setAsCorrespondenceAddress(payload) {
      this.$emit('set-as-correspondence-address', payload);
    },
  },
};
</script>
