import '@/plugins/compositionApi';
import { ref, computed } from '@vue/composition-api';

const state = ref({
  message: '',
  error: false,
});

const setSnackbar = (message = '', error = false) => {
  state.value.message = message;
  state.value.error = error;
};

const getSnackbar = computed(() => state.value);

const updateSnackbar = ({ message, error = false }, timeout = 1500) => {
  setSnackbar(message, error);
  setTimeout(() => setSnackbar(), timeout);
};

export { updateSnackbar, getSnackbar };
