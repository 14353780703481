<template>
  <div>
    <h4 class="text-sm font-bold">
      {{ sectionTitle }}
    </h4>
    <e-contact-group>
      <e-contact-detail
        v-for="contact in contacts"
        :key="contact.id"
        data-test="contact-group__item"
      >
        <template #data>
          <slot name="data" :item="contact" />
        </template>
        <template #tags>
          <e-chip
            v-for="tag in tags(contact)"
            :key="tag"
            class="bg-blue-600 text-white"
            data-test="contact__tag"
          >
            {{ tag }}
          </e-chip>
        </template>
        <template #actions>
          <e-menu
            :actions="actions(contact)"
            align-right
            data-test="contact__actions"
            v-on="actionsListeners(contact)"
          >
            <template #activator="{ interact }">
              <e-button
                variant="text"
                data-test="contact__manage"
                small
                @click="interact"
              >
                <e-icon icon="mdi-chevron-down" />Verwalten
              </e-button>
            </template>
          </e-menu>
        </template>
      </e-contact-detail>
    </e-contact-group>
  </div>
</template>

<script>
export default {
  name: 'ContactsBox',
  props: {
    sectionTitle: {
      type: String,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Function,
      required: true,
    },
    tags: {
      type: Function,
      default: () => [],
    },
  },
  methods: {
    actionsListeners(contact) {
      const { create, ...listeners } = this.$listeners;
      const actionsListeners = {};

      Object.keys(listeners).forEach((key) => {
        actionsListeners[key] = () => this.$emit(key, contact);
      });

      return actionsListeners;
    },
  },
};
</script>
