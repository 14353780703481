import { BasicResourceFactory } from '@/api/ApiResourceFactory';

/**
 * @type {BasicApiResource}
 * @private
 */
const { get } = BasicResourceFactory('time-blocks');

export const timeBlocks = {
  getAll: () => {
    return get({});
  },
};

/**
 * @typedef {Object} TimeBlock
 */
