<template>
  <fragment>
    <tr :key="examCenter.id" class="border-b border-blue-100 bg-blue-200">
      <td class="px-2 py-6">
        <e-button
          :disabled="!examCenter.rooms.length"
          variant="text"
          small
          data-test="exam-center-row-expand"
          @click="toggle"
        >
          <e-icon
            color="primary"
            size="3xl"
            :icon="
              isOpen && examCenter.rooms.length
                ? 'mdi-chevron-up'
                : 'mdi-chevron-down'
            "
          />
        </e-button>
      </td>
      <td class="truncate px-3 py-6">
        <router-link
          :to="{
            name: 'exam-center',
            params: { examCenterId: examCenter.id },
          }"
          data-test="exam-center-row-name"
        >
          {{ examCenter.name }}
        </router-link>
      </td>
      <td class="px-3 py-6"></td>
      <td class="truncate px-3 py-6" colspan="3">
        {{ primaryContactName }}
      </td>
      <td class="py-6 pl-3 pr-6">
        <div class="">
          <e-tooltip
            :content="
              examCenterHasBookings(examCenter)
                ? 'Löschen nicht möglich (Bookings)'
                : 'Löschen'
            "
            class="text-center"
            class-definitions="block"
          >
            <e-button
              :disabled="examCenterHasBookings(examCenter)"
              data-test="exam-center-row-delete"
              variant="text"
              small
              @click="deleteExamCenter"
            >
              <e-icon color="primary" icon="mdi-trash-can-outline" />
            </e-button>
          </e-tooltip>
        </div>
      </td>
    </tr>
    <template v-if="isOpen && examCenter.rooms.length">
      <tr
        v-for="room in examCenter.rooms"
        :key="room.id"
        class="odd:bg-blue-100"
        data-test="room-row"
      >
        <td class="py-6 pl-4 pr-3">
          <e-checkbox
            :id="`checkbox-room-${room.id}`"
            :check-value="room.id"
            :options="selected.some(({ id }) => id === room.id)"
            data-test="room-row-checkbox"
            @input="select(room)"
          />
        </td>
        <td class="truncate px-3 py-6">
          <router-link
            :to="{
              name: 'exam-center',
              params: { examCenterId: examCenter.id },
            }"
            data-test="room-row-name"
          >
            {{ room.name }}
          </router-link>
        </td>
        <td class="px-3 py-6">
          {{ room.capacity }}
        </td>
        <td class="px-3 py-6"></td>
        <td class="px-3 py-6">
          <chip-light v-if="room.isLight" />
        </td>
        <td class="px-3 py-6">
          <chip-time-block
            v-for="timeBlock in room.timeBlocks"
            :key="timeBlock.id"
            :start="timeBlock.startTime"
            :end="timeBlock.endTime"
          />
        </td>
        <td class="py-6 pl-3 pr-6">
          <div class="">
            <e-tooltip
              :content="
                room.examSessions.length > 0
                  ? 'Löschen nicht möglich (Bookings)'
                  : 'Löschen'
              "
              class="text-center"
              class-definitions="block"
            >
              <e-button
                :disabled="room.examSessions.length > 0"
                data-test="room-row-delete"
                variant="text"
                small
                @click="deleteRoom(room)"
              >
                <e-icon color="primary" icon="mdi-trash-can-outline" />
              </e-button>
            </e-tooltip>
          </div>
        </td>
      </tr>
    </template>
  </fragment>
</template>

<script>
import ChipLight from '@/components/common/ChipLight';
import ChipTimeBlock from '@/components/common/ChipTimeBlock';

export default {
  name: 'RowGroup',
  components: { ChipTimeBlock, ChipLight },
  props: {
    examCenter: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    primaryContactName() {
      const { givenName, familyName } = this.examCenter.contactPersons[0] || {};
      return `${givenName || ''}${familyName ? ' ' + familyName : ''}`;
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    select(room) {
      this.$emit('select', [room]);
    },
    deleteExamCenter() {
      this.$emit('delete-exam-center', this.examCenter);
    },
    deleteRoom(room) {
      this.$emit('delete-room', this.examCenter.id, room);
    },
    examCenterHasBookings(examCenter) {
      return !!examCenter.rooms.find((room) => room.examSessions.length > 0);
    },
  },
};
</script>
