export const formatDateToLocale = (date, locale = 'de-DE') =>
  new Date(date).toLocaleDateString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

export const getDateISOString = (date) => {
  const dateObj = date ? new Date(date) : new Date();

  return [
    dateObj.getFullYear(),
    (dateObj.getMonth() + 1).toString().padStart(2, '0'),
    dateObj.getDate().toString().padStart(2, '0'),
  ].join('-');
};

export const areDatesEqual = (dateA, dateB) =>
  dateA.getFullYear() === dateB.getFullYear() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getDate() === dateB.getDate();
