<template>
  <div>
    <e-toolbar>
      <portal-target name="examCenterSubmit" />
    </e-toolbar>
    <exam-center-form
      :value="initialValue"
      data-test="create-exam-center-form"
      @submit="createExamCenter"
    />
  </div>
</template>

<script>
import { updateSnackbar } from '@/store/modules/snackbar';
import ExamCenterForm from '@/components/exam-centers/ExamCenterForm';
import { examCenters as examCenterApi } from '@/api/ExamCenters';

export default {
  name: 'ExamCenterCreate',
  components: { ExamCenterForm },
  data() {
    return {
      initialValue: {
        id: null,
        name: null,
        location: null,
        directions: null,
        comment: null,
      },
    };
  },
  methods: {
    async createExamCenter(examCenter) {
      const payload = {
        name: examCenter.name,
        location: examCenter.location,
        directions: examCenter.directions,
        comment: examCenter.comment,
      };
      const { data } = await examCenterApi.post(payload);

      updateSnackbar({ message: 'Exam Center has been created' });

      return this.$router.push({
        name: 'exam-center',
        params: { examCenterId: data.id },
      });
    },
  },
};
</script>
