import { Sentry } from '@/plugins/sentry';

export default {
  namespaced: true,
  state: {
    errors: [],
  },
  actions: {
    pushError({ commit }, error) {
      commit('pushError', error);
    },
    dismissError({ commit }) {
      commit('shiftError');
    },
    reportError({ rootState }) {
      const name = ` ${rootState['currentUser/family_name']}, ${rootState['currentUser/given_name']}`;
      const email = rootState['currentUser/email'];
      Sentry.showReportDialog({
        user: { name, email },
      });
    },
  },
  mutations: {
    pushError(state, error) {
      state.errors.push(error);
    },
    shiftError(state) {
      state.errors.shift();
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  getters: {
    hasErrors: (state) => !!state.errors.length,
    totalErrors: (state) => state.errors.length,
    lastError: (state) => (state.errors.length ? state.errors[0] : null),
  },
};
