<template>
  <div>
    <form
      class="epos__exam-center-form"
      data-test="exam-center-form"
      @submit="onSubmit"
    >
      <validation-observer v-slot="{ invalid }" ref="observer" slim>
        <portal to="examCenterSubmit">
          <e-button
            :disabled="disabled || invalid || !hasChanged"
            variant="tertiary"
            small
            data-test="exam-center-submit"
            @click="onSubmit"
          >
            <e-icon icon="mdi-content-save-outline" class="mr-2" />
            Speichern
          </e-button>
        </portal>
        <div class="flex flex-col gap-x-6 lg:grid lg:grid-cols-12">
          <div class="col-span-6">
            <general v-model="examCenter" />
          </div>
          <div class="col-span-6 flex items-center justify-center pt-16">
            <img :src="imgExamCenter" height="160" alt="" aria-hidden="true" />
          </div>
        </div>
      </validation-observer>
    </form>
  </div>
</template>

<script>
import General from './General';
import { IMAGE_URLS } from '@/constants';
import _ from '@/plugins/lodash';

export default {
  name: 'ExamCenterForm',
  components: { General },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      examCenter: {
        id: null,
        name: null,
        location: null,
        directions: null,
        comment: null,
      },
    };
  },
  computed: {
    imgExamCenter() {
      return IMAGE_URLS.EXAM_CENTER;
    },
    hasChanged() {
      return !_.isEqual(this.examCenter, this.initialValue);
    },
    initialValue() {
      return _.pick(this.value, Object.keys(this.examCenter));
    },
  },
  mounted() {
    this.examCenter = this.initialValue;
  },
  methods: {
    async onSubmit() {
      const validForm = await this.$refs.observer.validate();

      if (!validForm) {
        return;
      }

      this.$emit('submit', this.examCenter);
    },
  },
};
</script>
