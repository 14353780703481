var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"epos__room-form col-span-12 mb-16",attrs:{"data-test":"contact-person-form"}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-col gap-x-6 lg:grid lg:grid-cols-12"},[_c('div',{staticClass:"col-span-12 my-3 mt-6"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:100","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-form__name","data-test":"address-form__name","required":"","errors":errors,"autofocus":_vm.address.id === null,"label":"Name"},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-8 my-3"},[_c('validation-provider',{attrs:{"name":"Strasse","rules":"required|max:175","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-form__street","data-test":"address-form__street","errors":errors,"label":"Strasse","required":""},model:{value:(_vm.value.street),callback:function ($$v) {_vm.$set(_vm.value, "street", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.street"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-4 my-3"},[_c('validation-provider',{attrs:{"name":"Hausnummer","rules":"required|max:15","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-form__houseNumber","data-test":"address-form__houseNumber","errors":errors,"label":"Hausnummer","required":""},model:{value:(_vm.value.houseNumber),callback:function ($$v) {_vm.$set(_vm.value, "houseNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.houseNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-4 my-3"},[_c('validation-provider',{attrs:{"name":"Postleitzahl","rules":"required|max:15","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-form__zip","data-test":"address-form__zip","errors":errors,"label":"Postleitzahl","required":""},model:{value:(_vm.value.zip),callback:function ($$v) {_vm.$set(_vm.value, "zip", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.zip"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-8 my-3"},[_c('validation-provider',{attrs:{"name":"Ort","rules":"required|max:100","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-form__city","data-test":"address-form__city","errors":errors,"label":"Ort","required":""},model:{value:(_vm.value.city),callback:function ($$v) {_vm.$set(_vm.value, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.city"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-full my-3"},[_c('validation-provider',{attrs:{"name":"Land","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"address-form__country","data-test":"address-form__country","multiple":false,"errors":errors,"options":_vm.countries,"label":"Land","required":"","option-label":"name","track-by":"countryIso","searchable":""},model:{value:(_vm.countryId),callback:function ($$v) {_vm.countryId=$$v},expression:"countryId"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-12 my-3"},[_c('p',{staticClass:"text-xs text-gray-700"},[_vm._v("* Pflichtfelder")])])]),_c('portal',{attrs:{"to":"form-modal-submit"}},[_c('e-button',{staticClass:"mt-18 ml-auto",attrs:{"variant":"primary","disabled":invalid || !_vm.hasChanged,"data-test":"modal-confirm"},on:{"click":function($event){return _vm.$emit('submit', Object.assign({}, _vm.value, {countryId: _vm.countryId}))}}},[_vm._v(" Speichern ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }