<template>
  <e-snackbar
    :value="!!snackbar.value.message"
    :timer-length="1500"
    :variant="snackbar.value.error ? 'error' : 'success'"
  >
    <template #message>
      {{ snackbar.value.message }}
    </template>
  </e-snackbar>
</template>

<script>
import { getSnackbar } from '@/store/modules/snackbar';

export default {
  name: 'Snackbar',
  computed: {
    snackbar() {
      return getSnackbar;
    },
  },
};
</script>
