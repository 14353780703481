import { BasicResourceFactory } from '@/api/ApiResourceFactory';
import { getDateISOString } from '@/utils/date';

/**
 * @type {BasicApiResource}
 * @private
 */
const { get, post, deleteById } = BasicResourceFactory('exam-sessions');

export const examSessions = {
  get: (from, until, examCenterId) =>
    get({
      ...(from ? { 'filter[from]': getDateISOString(from) } : {}),
      ...(until ? { 'filter[until]': getDateISOString(until) } : {}),
      ...(examCenterId ? { 'filter[examCenterId]': examCenterId } : {}),
    }),
  create: (dateString, roomId) => post({ date: dateString, roomId }),
  deleteById,
};

/**
 * @typedef {Object} ExamDate
 */
