<template>
  <section class="mt-10 py-3">
    <h3 class="font-bold uppercase text-blue-600">
      <slot name="headline" />
    </h3>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'SectionStructure',
};
</script>
