import { BasicResourceFactory } from '@/api/ApiResourceFactory';

/**
 * @type {BasicApiResource}
 * @private
 */
const { updateById, deleteById } = BasicResourceFactory('contact-persons');

export const formatContactPerson = (contactPerson) => {
  return {
    givenName: contactPerson.givenName,
    familyName: contactPerson.familyName,
    email: contactPerson.email,
    phone: contactPerson.phone,
    primary: contactPerson.primary,
  };
};

export const contactPersons = {
  update: (contactPersonId, payload) => {
    return updateById(contactPersonId, formatContactPerson(payload));
  },
  delete: (contactPersonId) => {
    return deleteById(contactPersonId);
  },
  setPrimary: (contactPersonId, payload) => {
    return updateById(contactPersonId, {
      ...formatContactPerson(payload),
      primary: true,
    });
  },
};

/**
 * @typedef {Object} ContactPerson
 */
