<template>
  <e-modal
    :title="title"
    size="lg"
    blue-50
    :is-open="isOpen"
    :auto-height="true"
    @close="$emit('close')"
  >
    <div class="modal-content">
      <div class="col-span-full">
        <div class="flex flex-col gap-x-6 px-8 lg:grid lg:grid-cols-12">
          <slot></slot>
          <div v-if="errorMessages" class="col-span-12">
            <div
              v-for="(message, index) in errorMessages"
              :key="index"
              class="text-bold text-sm text-error"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <portal-target
      name="form-modal-submit"
      class="m-8 flex h-9 justify-items-end"
      :slot-props="{ isLoading }"
    />
  </e-modal>
</template>

<script>
export default {
  name: 'FormModal',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
