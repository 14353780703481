<template>
  <address-form @submit="createAddress" />
</template>

<script>
import AddressForm from '@/components/exam-centers/AddressForm';
export default {
  name: 'AddressCreate',
  components: { AddressForm },
  props: {
    examCenterId: {
      type: String,
      required: true,
    },
  },
  methods: {
    createAddress(address) {
      this.$emit('create-address', address);
    },
  },
};
</script>
