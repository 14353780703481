<template>
  <section-structure>
    <template #headline>Räume im Prüfungszentrum</template>
    <e-simple-table :header-items="headerItems">
      <template #no-results>
        <div
          v-if="!examCenter.rooms.length"
          class="my-8 mx-auto flex max-w-sm flex-col items-center text-center"
        >
          <img
            :src="imgNoSearchResults"
            height="160"
            alt=""
            aria-hidden="true"
          />
          <div class="my-4 font-bold">Keine Räume vorhanden</div>
          <e-button
            data-test="exam-center-add-room"
            @click="$emit('create-room', examCenter)"
          >
            Raum hinzufügen
          </e-button>
        </div>
      </template>
      <template v-for="room in examCenter.rooms">
        <tr :key="room.id" class="odd:bg-blue-100">
          <td class="truncate px-3 py-6 font-bold">
            {{ room.name }}
          </td>
          <td class="px-3 py-6">
            {{ room.capacity }}
          </td>
          <td class="px-3 py-6">
            <chip-light v-if="room.isLight" />
          </td>
          <td class="px-3 py-6">
            <template v-for="timeBlock in room.timeBlocks">
              <chip-time-block
                :key="timeBlock.id"
                :start="timeBlock.startTime"
                :end="timeBlock.endTime"
              />
            </template>
          </td>
          <td class="px-3 py-6 text-right">
            <div class="">
              <e-tooltip content="Bearbeiten" class="text-center">
                <e-button
                  small
                  variant="text"
                  class="mx-3"
                  @click="$emit('update-room', room)"
                >
                  <e-icon color="primary" icon="mdi-pencil-outline" />
                </e-button>
              </e-tooltip>
              <e-tooltip
                :content="
                  room.examSessions.length > 0
                    ? 'Löschen nicht möglich (Bookings)'
                    : 'Löschen'
                "
                class="text-center"
              >
                <e-button
                  small
                  variant="text"
                  class="mx-3"
                  data-test="exam-center-delete-room"
                  :disabled="room.examSessions.length > 0"
                  @click="$emit('delete-room', room)"
                >
                  <e-icon color="primary" icon="mdi-trash-can-outline" />
                </e-button>
              </e-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </e-simple-table>
  </section-structure>
</template>

<script>
import ChipLight from '@/components/common/ChipLight';
import SectionStructure from '../common/SectionStructure';
import ESimpleTable from '../common/SimpleTable/ESimpleTable';
import { IMAGE_URLS } from '@/constants';
import ChipTimeBlock from '../common/ChipTimeBlock';

export default {
  name: 'RoomTable',
  components: { ChipLight, ChipTimeBlock, ESimpleTable, SectionStructure },
  props: {
    examCenter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headerItems: [
        {
          class: 'w-4/12 px-3',
          label: 'Name',
          key: 'name',
        },
        {
          class: 'w-1/12 px-3',
          label: 'Kapazität',
          key: 'capacity',
        },
        {
          class: 'w-1/12 px-3',
          label: 'Light',
          key: 'isLight',
        },
        {
          class: 'w-4/12 px-3',
          label: 'Zeitblock',
          key: 'timeBlocks',
        },
        {
          class: 'w-2/12 px-3',
          label: '',
          key: 'actions',
        },
      ],
    };
  },
  computed: {
    imgNoSearchResults() {
      return IMAGE_URLS.NO_RESULTS;
    },
  },
};
</script>
