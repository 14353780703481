<template>
  <form
    class="epos__room-form col-span-12 mb-16"
    data-test="contact-person-form"
  >
    <validation-observer v-slot="{ invalid }" ref="observer" slim>
      <div class="flex flex-col gap-x-6 lg:grid lg:grid-cols-12">
        <div class="col-span-12 my-3 mt-6">
          <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required|max:100"
            slim
          >
            <e-text-field
              id="address-form__name"
              v-model.trim="value.name"
              data-test="address-form__name"
              required
              :errors="errors"
              :autofocus="address.id === null"
              label="Name"
            />
          </validation-provider>
        </div>
        <div class="col-span-8 my-3">
          <validation-provider
            v-slot="{ errors }"
            name="Strasse"
            rules="required|max:175"
            slim
          >
            <e-text-field
              id="address-form__street"
              v-model.trim="value.street"
              data-test="address-form__street"
              :errors="errors"
              label="Strasse"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-4 my-3">
          <validation-provider
            v-slot="{ errors }"
            name="Hausnummer"
            rules="required|max:15"
            slim
          >
            <e-text-field
              id="address-form__houseNumber"
              v-model.trim="value.houseNumber"
              data-test="address-form__houseNumber"
              :errors="errors"
              label="Hausnummer"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-4 my-3">
          <validation-provider
            v-slot="{ errors }"
            name="Postleitzahl"
            rules="required|max:15"
            slim
          >
            <e-text-field
              id="address-form__zip"
              v-model.trim="value.zip"
              data-test="address-form__zip"
              :errors="errors"
              label="Postleitzahl"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-8 my-3">
          <validation-provider
            v-slot="{ errors }"
            name="Ort"
            rules="required|max:100"
            slim
          >
            <e-text-field
              id="address-form__city"
              v-model.trim="value.city"
              data-test="address-form__city"
              :errors="errors"
              label="Ort"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-full my-3">
          <validation-provider v-slot="{ errors }" name="Land" rules="required">
            <e-multiselect
              id="address-form__country"
              v-model="countryId"
              data-test="address-form__country"
              :multiple="false"
              :errors="errors"
              :options="countries"
              label="Land"
              required
              option-label="name"
              track-by="countryIso"
              searchable
            />
          </validation-provider>
        </div>
        <div class="col-span-12 my-3">
          <p class="text-xs text-gray-700">* Pflichtfelder</p>
        </div>
      </div>
      <portal to="form-modal-submit">
        <e-button
          variant="primary"
          :disabled="invalid || !hasChanged"
          class="mt-18 ml-auto"
          data-test="modal-confirm"
          @click="$emit('submit', { ...value, countryId })"
        >
          Speichern
        </e-button>
      </portal>
    </validation-observer>
  </form>
</template>

<script>
import { getCountries } from '@/constants/picklists/countries';
import _ from '@/plugins/lodash';

export default {
  name: 'AddressForm',
  props: {
    address: {
      type: Object,
      default: () => ({
        id: null,
        street: '',
      }),
    },
  },
  data() {
    return {
      value: this.address,
      countryId: {},
      countries: [],
    };
  },
  computed: {
    hasChanged() {
      return !_.isEqual(this.value, this.initialValue);
    },
    initialValue() {
      return _.pick(this.address, Object.keys(this.value));
    },
  },
  async mounted() {
    this.countries = await getCountries();
    this.countryId = this.countries.find(
      (item) => item.countryIso === this.value.countryId
    );
    this.value = this.initialValue;
  },
};
</script>
