export default {
  namespaced: true,
  state: {
    timeBlocks: [],
  },
  actions: {
    setTimeBlocks: async ({ commit }, data) => {
      commit('setTimeBlocks', data);
    },
  },
  mutations: {
    setTimeBlocks: (state, data) => {
      state.timeBlocks = data;
    },
  },
  getters: {
    getTimeBlocksAsOptions: (state) =>
      state.timeBlocks.map(({ id, name, startTime, endTime }) => ({
        id,
        text: `${name}, ${startTime}-${endTime}`,
      })),
  },
};
