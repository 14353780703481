<template>
  <div>
    <e-toolbar>
      <e-button
        :to="{ name: 'create' }"
        class="epos-button--small mr-4"
        data-test="create-exam-center"
        small
        type="routerLink"
        variant="tertiary"
      >
        <e-icon class="mr-2" icon="mdi-plus" />
        Prüfungszentrum hinzufügen
      </e-button>
      <e-menu
        id="toolbar__menu-time-blocks"
        :actions="timeBlocksActions"
        data-test="assign-time-blocks"
        @assign-time-block="bulkAssignTimeBlock"
      >
        <template #activator="{ interact }">
          <e-button
            id="toolbar__menu-time-blocks__activator"
            :disabled="!selected.length"
            class="epos-button--small mr-4"
            data-test="assign-time-blocks-button"
            small
            type="button"
            variant="tertiary"
            @click.stop.native="interact"
          >
            <e-icon class="mr-2" icon="mdi-clock-plus-outline" />
            Zeitblock zuordnen
            <e-icon icon="mdi-menu-down" />
          </e-button>
        </template>
      </e-menu>
    </e-toolbar>
    <error-banner />
    <section-structure :grid="false">
      <form class="mb-10" @submit.prevent="searchByName">
        <div class="flex">
          <e-text-field
            id="exam-centers__search-by-label"
            v-model="searchByNameValue"
            class="flex-grow"
            data-test="search-by-label-input"
            label="Suche nach Name des Prüfungszentrums oder des Raumes"
            rounded="rounded-l"
          />
          <e-button
            id="exam-centers__search-submit"
            aria-label="Suche"
            class="mt-px min-w-20 rounded-l-none"
            data-test="search-submit"
            variant="primary"
            action-type="submit"
          >
            <e-icon icon="mdi-magnify" />
          </e-button>
        </div>
      </form>
      <filter-toolbar
        v-if="activeFilters.length"
        :filters="activeFilters"
        @reset="resetFilters"
      />
      <e-checkbox-group v-model="selected" :items="rooms">
        <template #default="{ toggle, toggleAll, allSelected }">
          <div>
            <div class="col-span-full mb-7 flex justify-between">
              <e-checkbox
                id="exam-centers__checkbox--select-all"
                :options="allSelected"
                check-value="select-all"
                class="pl-4"
                data-test="checkbox-select-all"
                label="Alle auswählen"
                @change="toggleAll"
              />
              <p class="text-gray-700">
                Anzeige aller
                <span class="font-bold text-primary">{{
                  pagination.totalCount
                }}</span>
                Prüfungszentren
              </p>
            </div>
            <e-simple-table :header-items="headerItems" :is-loading="isLoading">
              <template #th-name>
                <span class="uppercase">
                  Name
                  <e-button
                    data-test="table-sort-by-name"
                    small
                    variant="text"
                    @click="sortByName"
                  >
                    <e-icon
                      :icon="
                        sort === ''
                          ? 'sort-alphabetical-ascending'
                          : 'sort-alphabetical-descending'
                      "
                      color="primary"
                      size="sm"
                    />
                  </e-button>
                </span>
              </template>
              <template #th-light="{ item }">
                <e-simple-table-filter
                  id="exam-centers__filter-light"
                  :label="item.label"
                  :options="filtersByIsLightOptions"
                  :value="$route.query.isLight || ''"
                  data-test="table-filter-by-is-light"
                  @input="filterByIsLight"
                />
              </template>
              <template #th-time-block="{ item }">
                <e-simple-table-filter
                  id="exam-centers__filter-timeblock"
                  :label="item.label"
                  :options="filtersByTimeBlocksOptions"
                  :value="$route.query.timeBlocks || ''"
                  data-test="table-filter-by-timeblocks"
                  @input="filterByTimeBlocks"
                />
              </template>
              <row-group
                v-for="examCenter in examCenters"
                :key="examCenter.id"
                :exam-center="examCenter"
                :selected="selected"
                @select="toggle"
                @delete-exam-center="goToExamCenterDelete"
                @delete-room="goToRoomDelete"
              />
              <template #no-results>
                <div
                  v-if="!examCenters.length"
                  class="my-8 mx-auto flex max-w-sm flex-col items-center text-center"
                  data-test="no-results"
                >
                  <img
                    :src="imgNoSearchResults"
                    alt=""
                    aria-hidden="true"
                    height="160"
                  />
                  <p class="mt-6 font-bold">Keine Prüfungszentren gefunden.</p>
                  <e-button
                    class="mt-6"
                    data-test="reset-filters"
                    variant="primary"
                    @click="() => resetFilters()"
                  >
                    Alle verfügbaren Prüfungszentren anzeigen
                  </e-button>
                </div>
              </template>
            </e-simple-table>
          </div>
        </template>
      </e-checkbox-group>
      <div class="mt-16 flex justify-end">
        <e-pagination :length="pagesLength" :value="page" @input="changePage" />
      </div>
    </section-structure>
    <router-view
      :is-open="$route.meta.showDeleteModal"
      name="deletion-modal"
      @close="closeModal"
      @delete-success="getExamCenters"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { updateSnackbar } from '@/store/modules/snackbar';
import { Api } from '@/api';
import { IMAGE_URLS } from '@/constants';
import ErrorBanner from '@/components/common/ErrorBanner';
import ESimpleTable from '@/components/common/SimpleTable/ESimpleTable';
import SectionStructure from '@/components/common/SectionStructure';
import ESimpleTableFilter from '@/components/common/SimpleTable/ESimpleTableFilter';
import RowGroup from '@/components/exam-centers/RowGroup';
import { getIds } from '@/utils/formatters';
import FilterToolbar from '@/components/common/FilterToolbar';

export default {
  name: 'Index',
  components: {
    FilterToolbar,
    ESimpleTableFilter,
    ErrorBanner,
    ESimpleTable,
    SectionStructure,
    RowGroup,
  },
  data() {
    return {
      isLoading: true,
      sort: '',
      pagination: { limit: 10, offset: 0, totalCount: 0 },
      examCenters: [],
      selected: [],
      searchByNameValue: '',
      filtersByIsLightOptions: [
        { label: 'Alle anzeigen', value: '' },
        { label: 'Nur Light', value: 'true' },
        { label: 'Nur Standard', value: 'false' },
      ],
      headerItems: [
        {
          class: 'w-10 px-2',
          label: '',
          key: 'prepend',
        },
        {
          class: 'w-auto px-3',
          label: 'Name',
          key: 'name',
        },
        {
          class: 'w-1/12 px-3',
          label: 'Kapazität',
          key: 'capacity',
        },
        {
          class: 'w-2/12 px-3',
          label: 'Ansprechpartner',
          key: 'contactPersons',
        },
        {
          class: 'w-20 px-3',
          label: 'Light',
          key: 'light',
        },
        {
          class: 'w-3/12 px-3',
          label: 'Zeitblock',
          key: 'time-block',
        },
        {
          class: 'w-16 pl-3 pr-6',
          label: '',
          key: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTimeBlocksAsOptions: 'timeBlocks/getTimeBlocksAsOptions',
    }),
    filtersByTimeBlocksOptions() {
      return [
        { label: 'Alle anzeigen', value: '' },
        ...this.getTimeBlocksAsOptions.map(({ id, text }) => ({
          label: text,
          value: id,
        })),
      ];
    },
    activeFilters() {
      const filters = [];
      if (this.$route.query.timeBlocks) {
        const timeBlock = this.getTimeBlocksAsOptions.find(
          ({ id }) => id === this.$route.query.timeBlocks
        );
        filters.push({
          type: 'Zeitblock',
          value: timeBlock?.text,
          name: 'timeBlocks',
        });
      }
      if (this.$route.query.isLight) {
        const filter = {
          type: 'Light',
          value: 'Nur Light',
          name: 'isLight',
        };
        if (this.$route.query.isLight === 'false') {
          filter.value = 'Nur Standard';
        }
        filters.push(filter);
      }

      return filters;
    },
    page() {
      return (
        (parseInt(this.pagination.offset) + parseInt(this.pagination.limit)) /
        parseInt(this.pagination.limit)
      );
    },
    rooms() {
      return this.examCenters.flatMap(({ rooms }) => rooms);
    },
    timeBlocksActions() {
      return this.getTimeBlocksAsOptions.map((item) => ({
        ...item,
        event: 'assign-time-block',
      }));
    },
    imgNoSearchResults() {
      return IMAGE_URLS.NO_RESULTS;
    },
    pagesLength() {
      const length = Math.ceil(
        this.pagination.totalCount / this.pagination.limit
      );

      return length ?? 1;
    },
  },
  mounted() {
    this.getExamCenters();
  },
  methods: {
    closeModal() {
      this.$router.push({ name: 'overview', query: this.$route.query });
    },
    resetFilters(filterName) {
      const params = filterName
        ? { [filterName]: '' }
        : {
            name: '',
            isLight: '',
            timeBlocks: '',
            limit: 10,
            offset: 0,
          };

      this.getExamCenters(params);
    },
    sortByName() {
      this.sort = this.sort === '' ? '-name' : '';
      this.getExamCenters({ sort: this.sort });
    },
    filterByIsLight(isLight) {
      this.getExamCenters({ isLight });
    },
    filterByTimeBlocks(timeBlocks) {
      this.getExamCenters({ timeBlocks });
    },
    searchByName() {
      this.getExamCenters({ name: this.searchByNameValue });
    },
    changePage(value) {
      const offset = (value - 1) * this.pagination.limit;
      this.getExamCenters({ offset });
    },
    goToExamCenterDelete(examCenter) {
      this.$router.push({
        name: 'exam-centers-delete',
        params: { examCenterId: examCenter.id, examCenter },
        query: this.$route.query,
      });
    },
    goToRoomDelete(examCenterId, room) {
      this.$router.push({
        name: 'exam-centers-room-delete',
        query: this.$route.query,
        params: {
          examCenterId,
          roomId: room.id,
          room,
        },
      });
    },
    generateUrlQuery(params = {}) {
      const { name, isLight, timeBlocks, limit, offset, sort } = {
        ...this.pagination,
        ...this.$route.query,
        ...params,
      };
      return {
        ...(name ? { name } : {}),
        ...(isLight === 'true' || isLight === 'false' ? { isLight } : {}),
        ...(timeBlocks?.length ? { timeBlocks } : {}),
        ...(sort === '-name' ? { sort } : {}),
        limit,
        offset,
      };
    },
    async getExamCenters(params = {}) {
      try {
        const filterLightRooms = (examCenters, expectedValue) =>
          examCenters.map(({ rooms, ...examCenter }) => ({
            ...examCenter,
            rooms: rooms.filter((room) => room.isLight === expectedValue),
          }));

        const filterTimeBlocks = (examCenters, expectedValue) =>
          examCenters.map(({ rooms, ...examCenter }) => {
            return {
              ...examCenter,
              rooms: rooms.filter((room) =>
                room.timeBlocks.some(
                  (timeBlock) => timeBlock.id === expectedValue
                )
              ),
            };
          });

        const urlQuery = this.generateUrlQuery(params);
        const { data } = await Api.examCenters.get(urlQuery);
        this.isLoading = true;

        if (data.pagination.totalCount < urlQuery.offset) {
          return this.getExamCenters({ offset: 0 });
        }

        await this.$router.push({ ...this.$route, query: urlQuery });

        this.examCenters = data.data;
        if (urlQuery.isLight) {
          this.examCenters = filterLightRooms(
            this.examCenters,
            urlQuery.isLight === 'true'
          );
        }
        if (urlQuery.timeBlocks) {
          this.examCenters = filterTimeBlocks(
            this.examCenters,
            urlQuery.timeBlocks
          );
        }

        this.pagination = data.pagination;

        this.sort = urlQuery.sort || '';

        this.searchByNameValue = urlQuery.name;
      } finally {
        this.isLoading = false;
      }
    },
    async bulkAssignTimeBlock(timeBlockId) {
      const rooms = this.selected.filter(
        ({ timeBlocks }) => !getIds(timeBlocks).includes(timeBlockId)
      );

      if (!rooms.length) {
        updateSnackbar({
          message: 'Timeblocks already assigned for selected rooms',
        });
        return;
      }

      await Promise.all(
        rooms.map((room) => Api.rooms.addTimeblock(room.id, { timeBlockId }))
      );
      updateSnackbar({
        message: 'Timeblocks were assigned successfully',
      });
      await this.getExamCenters();
    },
  },
};
</script>
