import AddressCreate from '@/views/addresses/AddressCreate';
import AddressDelete from '@/views/addresses/AddressDelete';
import AddressUpdate from '@/views/addresses/AddressUpdate';
import ContactPersonCreate from '@/views/contact-persons/ContactPersonCreate';
import ContactPersonDelete from '@/views/contact-persons/ContactPersonDelete';
import ContactPersonUpdate from '@/views/contact-persons/ContactPersonUpdate';
import ErrorView from '@/views/errors/ErrorView';
import ExamCenterCalendar from '@/views/exam-centers/ExamCenterCalendar';
import ExamCenterDelete from '@/views/exam-centers/ExamCenterDelete';
import ExamCenterDetails from '@/views/exam-centers/ExamCenterDetails';
import ExamCentersCalendar from '@/views/exam-centers/ExamCentersCalendar';
import Index from '@/views/Index';
import RoomCreate from '@/views/rooms/RoomCreate';
import RoomDelete from '@/views/rooms/RoomDelete';
import { Routes } from '@epos/core-applets';
import Vue from 'vue';
import VueRouter from 'vue-router';
import ExamCenterCreate from '../views/exam-centers/ExamCenterCreate';
import ExamCenterUpdate from '../views/exam-centers/ExamCenterUpdate';
import {
  addressGuard,
  contactPersonGuard,
  examCenterGuard,
  roomGuard,
} from './utils/guards';

import RoomUpdate from '@/views/rooms/RoomUpdate';

Vue.use(VueRouter);

const examCenterMeta = {
  headline: 'Prüfungszentrenprofil',
  showFormModal: false,
  modalTitle: '',
  links: [
    {
      label: 'Profil',
      to: { name: 'exam-center' },
    },
    {
      label: 'Kalender',
      to: { name: 'exam-center-calendar' },
    },
  ],
};

/**
 * @param path
 * @param name
 * @param meta
 * @param routes
 */
const makeNestedRoutes = ({ path = '', name, meta = {} }, routes) =>
  routes.map((route) => ({
    ...route,
    path: path ? `${path}/${route.path}` : route.path,
    name: name ? `${name}.${route.name}` : route.name,
    meta: { ...route.meta, ...meta },
  }));

const routes = makeNestedRoutes(
  {
    path: Routes.EXAM_CENTER_SERVICES.BASE,
    meta: {
      title: 'Prüfungszentren',
    },
  },
  [
    {
      path: '',
      component: Index,
      name: 'overview',
      meta: {
        headline: 'Prüfungszentren',
        subheadline: 'Übersicht',
      },
      children: [
        {
          path: ':examCenterId/delete',
          name: 'exam-centers-delete',
          components: { 'deletion-modal': ExamCenterDelete },
          props: { 'deletion-modal': true },
          meta: {
            showDeleteModal: true,
            headline: 'Prüfungszentren',
            subheadline: 'Übersicht',
          },
          beforeEnter: examCenterGuard,
        },
        {
          path: ':examCenterId/:roomId/delete',
          name: 'exam-centers-room-delete',
          components: { 'deletion-modal': RoomDelete },
          props: { 'deletion-modal': true },
          meta: {
            ...examCenterMeta,
            showDeleteModal: true,
          },
          beforeEnter: roomGuard,
        },
      ],
    },
    {
      path: 'calendar',
      name: 'calendar',
      component: ExamCentersCalendar,
      props: true,
      meta: {
        headline: 'Prüfungszentren',
        subheadline: 'Kalender',
      },
    },
    {
      path: 'create',
      name: 'create',
      component: ExamCenterCreate,
      props: true,
      meta: {
        headline: 'Prüfungszentrenprofil',
        subheadline: 'Neues Prüfungszentrum',
      },
    },
    {
      path: ':examCenterId',
      meta: examCenterMeta,
      component: ExamCenterUpdate,
      props: true,
      children: [
        {
          path: 'details',
          name: 'exam-center',
          props: true,
          meta: examCenterMeta,
          component: ExamCenterDetails,
          beforeEnter: examCenterGuard,
          children: [
            {
              path: 'rooms/:roomId/delete',
              name: 'exam-center-room-delete',
              components: { 'deletion-modal': RoomDelete },
              props: { 'deletion-modal': true },
              meta: {
                ...examCenterMeta,
                showDeleteModal: true,
              },
              beforeEnter: roomGuard,
            },
            {
              path: 'rooms/create',
              name: 'exam-center-room-create',
              component: RoomCreate,
              props: true,
              meta: {
                ...examCenterMeta,
                showFormModal: true,
                modalTitle: 'Raum hinzufügen',
              },
            },
            {
              path: 'rooms/:roomId/update',
              name: 'exam-center-room-update',
              component: RoomUpdate,
              props: true,
              meta: {
                ...examCenterMeta,
                showFormModal: true,
                modalTitle: 'Raum bearbeiten',
              },
              beforeEnter: roomGuard,
            },
            {
              path: 'contact-persons/create',
              name: 'exam-center-contact-persons-create',
              component: ContactPersonCreate,
              props: true,
              meta: {
                ...examCenterMeta,
                showFormModal: true,
                modalTitle: 'Kontaktperson hinzufügen',
              },
            },
            {
              path: 'contact-persons/:contactPersonId/update',
              name: 'exam-center-contact-persons-update',
              component: ContactPersonUpdate,
              props: true,
              meta: {
                ...examCenterMeta,
                showFormModal: true,
                modalTitle: 'Kontaktperson bearbeiten',
              },
              beforeEnter: contactPersonGuard,
            },
            {
              path: 'contact-persons/:contactPersonId/delete',
              name: 'exam-center-contact-persons-delete',
              components: { 'deletion-modal': ContactPersonDelete },
              props: { 'deletion-modal': true },
              meta: {
                ...examCenterMeta,
                showDeleteModal: true,
              },
              beforeEnter: contactPersonGuard,
            },
            {
              path: 'addresses/create',
              name: 'exam-center-addresses-create',
              component: AddressCreate,
              props: true,
              meta: {
                ...examCenterMeta,
                showFormModal: true,
                modalTitle: 'Adresse hinzufügen',
              },
            },
            {
              path: 'addresses/:addressId/update',
              name: 'exam-center-addresses-update',
              component: AddressUpdate,
              props: true,
              meta: {
                ...examCenterMeta,
                showFormModal: true,
                modalTitle: 'Adresse bearbeiten',
              },
              beforeEnter: addressGuard,
            },
            {
              path: 'addresses/:addressId/delete',
              name: 'exam-center-addresses-delete',
              components: { 'deletion-modal': AddressDelete },
              props: { 'deletion-modal': true },
              meta: {
                ...examCenterMeta,
                showDeleteModal: true,
              },
              beforeEnter: addressGuard,
            },
          ],
        },
        {
          path: 'calendar',
          name: 'exam-center-calendar',
          props: true,
          meta: examCenterMeta,
          component: ExamCenterCalendar,
          beforeEnter: examCenterGuard,
        },
      ],
    },
    {
      path: '*',
      alias: '/:alias',
      component: ErrorView,
      name: 'not-found',
      props: true,
      meta: {
        title: 'Seite nicht gefunden',
        headline: 'Seite nicht gefunden',
      },
    },
  ]
);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    return savedPosition ?? { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | EPOS | IU`;
  }

  document.getElementById('epos__core-user')?.scrollIntoView();

  next();
});

export default router;
