<template>
  <fragment>
    <table class="mt-6 w-full table-fixed">
      <thead>
        <tr class="border-b border-gray-700">
          <th
            v-for="item of headerItems"
            :key="item.key"
            :class="item.class"
            class="pb-4 text-left text-blue-600 first:pl-3"
          >
            <slot :name="`th-${item.key}`" :item="item">
              <e-tooltip v-if="item.tooltip" :content="item.tooltip">
                <span class="uppercase">{{ item.label }}</span>
              </e-tooltip>
              <template v-else>
                <span class="uppercase">{{ item.label }}</span>
              </template>
            </slot>
          </th>
        </tr>
      </thead>
      <tbody v-show="!isLoading">
        <slot />
      </tbody>
    </table>
    <div v-if="isLoading">
      <e-skeleton-loader-item
        v-for="index in [0, 1, 3, 4, 5]"
        :key="index"
        class="relative overflow-hidden border-b border-blue-100 px-3 py-6"
      />
    </div>
    <slot v-else name="no-results" />
  </fragment>
</template>

<script>
export default {
  name: 'ESimpleTable',
  props: {
    headerItems: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
