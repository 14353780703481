<template>
  <e-infobanner
    v-if="hasErrors"
    variant="error"
    style="top: 50px"
    class="sticky z-90"
  >
    <template #message>
      ({{ totalErrors }}) {{ lastError.error ? `${lastError.error}:` : '' }}
      {{ lastError.message }}.
    </template>
    <template #actions>
      <e-button variant="blank" @click="reportError">
        <span class="uppercase text-white hover:underline">Melden</span>
      </e-button>
      <e-button variant="blank" @click="reloadPage">
        <span class="uppercase text-white hover:underline"> Neu laden</span>
      </e-button>
      <e-button variant="blank" @click="dismissError">
        <span class="text-white">
          <e-icon icon="close" />
        </span>
      </e-button>
    </template>
  </e-infobanner>
</template>

<script>
import { EInfobanner } from '@careerpartner/nitro';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('errors');

export default {
  name: 'ErrorBanner',
  components: {
    EInfobanner,
  },
  computed: {
    ...mapGetters(['hasErrors', 'totalErrors', 'lastError']),
  },
  methods: {
    ...mapActions(['dismissError', 'reportError']),
    reloadPage() {
      location.reload();
    },
  },
};
</script>
