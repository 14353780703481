import { BasicResourceFactory } from '@/api/ApiResourceFactory';

/**
 * @type {BasicApiResource}
 * @private
 */
const { postSubPathById, updateById, deleteSubPathById, deleteById } =
  BasicResourceFactory('rooms');

export const formatRoom = (room) => {
  return {
    name: room.name,
    capacity: parseInt(room.capacity),
    isLight: room.isLight === true,
  };
};

export const rooms = {
  update: (roomId, payload) => updateById(roomId, formatRoom(payload)),
  deleteById: (roomId) => deleteById(roomId),
  addTimeblock: (roomId, payload) =>
    postSubPathById(roomId, 'time-blocks', payload),
  removeTimeblock: (roomId, timeBlockId) =>
    deleteSubPathById(roomId, timeBlockId, 'time-blocks'),
};

/**
 * @typedef {Object} Room
 */
