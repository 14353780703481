<template>
  <div>
    <error-banner />
    <section class="mt-9 grid grid-cols-12 gap-x-6 gap-y-7 px-4 pb-8">
      <div class="col-span-full">
        <img :src="errorImageUrl" class="mx-auto" />
      </div>
      <div class="col-span-full text-center">
        <slot>
          <p class="">
            {{ error.message }}
          </p>
        </slot>
      </div>
    </section>
  </div>
</template>

<script>
import { IMAGE_URLS } from '@/constants';
import ErrorBanner from '@/components/common/ErrorBanner';

export default {
  name: 'Error',
  components: { ErrorBanner },
  props: {
    error: {
      type: Object,
      default: () => ({ message: 'Etwas ist schief gelaufen.' }),
    },
  },
  computed: {
    errorImageUrl() {
      return IMAGE_URLS.ERROR;
    },
  },
};
</script>
