import { examCenters } from '@/api/ExamCenters';
import store from '@/store';

const setRouteParamExamCenter = async (to) => {
  if (to.params.examCenter?.id === to.params.examCenterId) {
    return;
  }

  const { data } = await examCenters.getById(to.params.examCenterId);

  to.params.examCenter = data;
};

export const examCenterGuard = async (to, from, next) => {
  await setRouteParamExamCenter(to);
  next();
};

export const roomGuard = async (to, from, next) => {
  await setRouteParamExamCenter(to);

  to.params.room =
    to.params.room ||
    to.params.examCenter.rooms.find(({ id }) => id === to.params.roomId);

  if (to.params.roomId && !to.params.room) {
    await store.dispatch('errors/pushError', {
      message: 'Der Raum ist nicht vorhanden',
    });

    next({ name: 'overview', query: to.query });
  } else {
    next();
  }
};

export const addressGuard = async (to, from, next) => {
  await setRouteParamExamCenter(to);

  to.params.address =
    to.params.address ||
    to.params.examCenter.addresses.find(({ id }) => id === to.params.addressId);

  if (to.params.addressId && !to.params.address) {
    await store.dispatch('errors/pushError', {
      message: 'Die Adresse ist nicht vorhanden',
    });
    next({ name: 'overview', query: to.query });
  } else {
    next();
  }
};

export const contactPersonGuard = async (to, from, next) => {
  await setRouteParamExamCenter(to);

  to.params.contactPerson =
    to.params.contactPerson ||
    to.params.examCenter.contactPersons.find(
      ({ id }) => id === to.params.contactPersonId
    );

  if (to.params.contactPersonId && !to.params.contactPerson) {
    await store.dispatch('errors/pushError', {
      message: 'Die Kontaktperson ist nicht vorhanden',
    });

    next({ name: 'overview', query: to.query });
  } else {
    next();
  }
};
