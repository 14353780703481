import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { version } from '../../package.json';

export default function sentryInit() {
  const dsn = SECRETS.SENTRY_DSN;

  if (!dsn) {
    // eslint-disable-next-line no-console
    console.warn(`Could not initialize Sentry! \n Sentry DSN is undefined`);
    return;
  }

  try {
    Sentry.init({
      Vue,
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.DEPLOYMENT_ENV,
      logErrors: true,
      release: version,
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: process.env.DEPLOYMENT_ENV === 'dev' ? 1.0 : 0.25,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Could not initialize Sentry!');
  }
}

Vue.config.errorHandler = (error) => {
  Sentry.captureException(error);
  throw error;
};

export { Sentry };
