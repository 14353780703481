import Vue from 'vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '@careerpartner/nitro/dist/styles.css';
import '@/assets/tailwind.css';

import {
  EButton,
  ECard,
  ECheckbox,
  ECheckboxGroup,
  EChip,
  EContactDetail,
  EContactGroup,
  EIcon,
  EMenu,
  EModal,
  EMultiselect,
  EPagination,
  ERadioGroup,
  ERadioButton,
  ESkeletonLoader,
  ESkeletonLoaderItem,
  ESnackbar,
  ETextField,
  EToolbar,
  ETooltip,
  ETextarea,
  ECalendar,
  EDualPicker,
  EDualPickerListItem,
} from '@careerpartner/nitro';

Vue.component('EButton', EButton);
Vue.component('ECalendar', ECalendar);
Vue.component('ECard', ECard);
Vue.component('ECheckbox', ECheckbox);
Vue.component('ECheckboxGroup', ECheckboxGroup);
Vue.component('EChip', EChip);
Vue.component('EContactDetail', EContactDetail);
Vue.component('EContactGroup', EContactGroup);
Vue.component('EDualPicker', EDualPicker);
Vue.component('EDualPickerListItem', EDualPickerListItem);
Vue.component('EIcon', EIcon);
Vue.component('EMenu', EMenu);
Vue.component('EModal', EModal);
Vue.component('EMultiselect', EMultiselect);
Vue.component('EPagination', EPagination);
Vue.component('ERadioButton', ERadioButton);
Vue.component('ERadioGroup', ERadioGroup);
Vue.component('ESkeletonLoader', ESkeletonLoader);
Vue.component('ESkeletonLoaderItem', ESkeletonLoaderItem);
Vue.component('ESnackbar', ESnackbar);
Vue.component('ETextField', ETextField);
Vue.component('ETextArea', ETextarea);
Vue.component('EToolbar', EToolbar);
Vue.component('ETooltip', ETooltip);
