export const IMAGE_URLS = Object.freeze({
  ERROR:
    'https://res.cloudinary.com/iubh/image/upload/v1625224956/IT%20-%20Epos%20/illustrations/epos-error.svg',
  NO_RESULTS:
    'https://res.cloudinary.com/iubh/image/upload/v1633072017/IT%20-%20Epos%20/illustrations/epos-no-search-results.svg',
  OVERLAYS: {
    DELETE:
      'https://res.cloudinary.com/iubh/image/upload/v1633071257/IT%20-%20Epos%20/illustrations/epos-delete.svg',
  },
  EXAM_CENTER:
    'https://res.cloudinary.com/iubh/image/upload/v1638265213/IT%20-%20Epos%20/illustrations/epos-exam-service-profile-center.svg',
});
