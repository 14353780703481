<template>
  <deletion-modal
    :is-open="isOpen"
    data-test="confirmation-modal"
    @close="close"
    @confirm="deleteAddress"
  >
    <p class="pb-6 font-bold">
      Bist Du sicher, dass Du die Adresse löschen möchtest?
    </p>
    <p class="pb-6" data-test="modal-address-name">
      {{ address.name }}
    </p>
    <p class="pb-6 text-xs text-gray-600">
      Adressen werden archiviert und können NICHT wiederhergestellt werden.
    </p>
  </deletion-modal>
</template>

<script>
import { Api } from '@/api';
import DeletionModal, {
  useDeletionModal,
} from '@/components/common/DeletionModal';

const DELETE_SUCCESS_MESSAGE = 'The Address has been deleted';

export default {
  name: 'AddressDelete',
  components: { DeletionModal },
  props: {
    address: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { close, confirm } = useDeletionModal(
      DELETE_SUCCESS_MESSAGE,
      context
    );

    const deleteAddress = () =>
      confirm(() => Api.addresses.deleteById(props.address.id));

    return {
      close,
      deleteAddress,
    };
  },
};
</script>
