import { updateSnackbar } from '@/store/modules/snackbar';

export const useDeletionModal = (message, context) => {
  const close = () => context.emit('close');

  const confirm = async (asyncCallback) => {
    const { success } = await asyncCallback();

    if (success) {
      updateSnackbar({
        message: message,
      });

      context.emit('delete-success');
    } else {
      context.emit('delete-failed');
    }

    close();
  };

  return {
    close,
    confirm,
  };
};
