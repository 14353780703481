var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-structure',{scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" Allgemeine Informationen ")]},proxy:true}])},[_c('div',{staticClass:"col-span-12 my-6"},[_c('validation-provider',{attrs:{"name":"Bezeichnung","rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"exam-center-form__name","errors":errors,"label":"Bezeichung des Prüfungszentrums","data-test":"exam-center-form__name","required":""},model:{value:(_vm.examCenter.name),callback:function ($$v) {_vm.$set(_vm.examCenter, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"examCenter.name"}})]}}])})],1),_c('div',{staticClass:"col-span-12 my-6"},[_c('validation-provider',{attrs:{"name":"Ort","rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"exam-center-form__location","data-test":"exam-center-form__location","errors":errors,"label":"Ort","required":""},model:{value:(_vm.examCenter.location),callback:function ($$v) {_vm.$set(_vm.examCenter, "location", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"examCenter.location"}})]}}])})],1),_c('div',{staticClass:"col-span-12 mt-6 mb-3"},[_c('validation-provider',{attrs:{"name":"Anfahrtsbeschreibung","rules":"max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-area',{attrs:{"id":"exam-center-form__directions","label":"Anfahrtsbeschreibung","data-test":"exam-center-form__directions","textarea-classes":['h-24'],"resize":false,"errors":errors,"max-length":255,"description":"Maximal 255 Zeichen."},model:{value:(_vm.examCenter.directions),callback:function ($$v) {_vm.$set(_vm.examCenter, "directions", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"examCenter.directions"}})]}}])})],1),_c('div',{staticClass:"col-span-12 mt-3"},[_c('validation-provider',{attrs:{"name":"Kommentar","rules":"max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-area',{attrs:{"id":"exam-center-form__comment","label":"Kommentar","data-test":"exam-center-form__comment","textarea-classes":['h-24'],"resize":false,"errors":errors,"max-length":255,"description":"Maximal 255 Zeichen."},model:{value:(_vm.examCenter.comment),callback:function ($$v) {_vm.$set(_vm.examCenter, "comment", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"examCenter.comment"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }