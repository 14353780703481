import singleSpaVue from 'single-spa-vue';
import Vue from 'vue';

import App from '@/App.vue';
import '@/config/set-public-path';
import '@/plugins';
import { sentryInit } from '@/plugins';
import router from '@/router';
import store from '@/store';
import './assets/tailwind.css';

Vue.config.productionTip = false;

const CONTAINER_SELECTOR = '#app-placeholder';

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    store,
    render(h) {
      return h(App);
    },
    el: CONTAINER_SELECTOR,
  },
});

export async function mount(props) {
  sentryInit();
  await vueLifecycles.mount(props);
}

export const bootstrap = vueLifecycles.bootstrap;
export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [CONTAINER_SELECTOR],
  },
};
