var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('tr',{key:_vm.examCenter.id,staticClass:"border-b border-blue-100 bg-blue-200"},[_c('td',{staticClass:"px-2 py-6"},[_c('e-button',{attrs:{"disabled":!_vm.examCenter.rooms.length,"variant":"text","small":"","data-test":"exam-center-row-expand"},on:{"click":_vm.toggle}},[_c('e-icon',{attrs:{"color":"primary","size":"3xl","icon":_vm.isOpen && _vm.examCenter.rooms.length
              ? 'mdi-chevron-up'
              : 'mdi-chevron-down'}})],1)],1),_c('td',{staticClass:"truncate px-3 py-6"},[_c('router-link',{attrs:{"to":{
          name: 'exam-center',
          params: { examCenterId: _vm.examCenter.id },
        },"data-test":"exam-center-row-name"}},[_vm._v(" "+_vm._s(_vm.examCenter.name)+" ")])],1),_c('td',{staticClass:"px-3 py-6"}),_c('td',{staticClass:"truncate px-3 py-6",attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.primaryContactName)+" ")]),_c('td',{staticClass:"py-6 pl-3 pr-6"},[_c('div',{},[_c('e-tooltip',{staticClass:"text-center",attrs:{"content":_vm.examCenterHasBookings(_vm.examCenter)
              ? 'Löschen nicht möglich (Bookings)'
              : 'Löschen',"class-definitions":"block"}},[_c('e-button',{attrs:{"disabled":_vm.examCenterHasBookings(_vm.examCenter),"data-test":"exam-center-row-delete","variant":"text","small":""},on:{"click":_vm.deleteExamCenter}},[_c('e-icon',{attrs:{"color":"primary","icon":"mdi-trash-can-outline"}})],1)],1)],1)])]),(_vm.isOpen && _vm.examCenter.rooms.length)?_vm._l((_vm.examCenter.rooms),function(room){return _c('tr',{key:room.id,staticClass:"odd:bg-blue-100",attrs:{"data-test":"room-row"}},[_c('td',{staticClass:"py-6 pl-4 pr-3"},[_c('e-checkbox',{attrs:{"id":("checkbox-room-" + (room.id)),"check-value":room.id,"options":_vm.selected.some(function (ref) {
                      var id = ref.id;

                      return id === room.id;
              }),"data-test":"room-row-checkbox"},on:{"input":function($event){return _vm.select(room)}}})],1),_c('td',{staticClass:"truncate px-3 py-6"},[_c('router-link',{attrs:{"to":{
            name: 'exam-center',
            params: { examCenterId: _vm.examCenter.id },
          },"data-test":"room-row-name"}},[_vm._v(" "+_vm._s(room.name)+" ")])],1),_c('td',{staticClass:"px-3 py-6"},[_vm._v(" "+_vm._s(room.capacity)+" ")]),_c('td',{staticClass:"px-3 py-6"}),_c('td',{staticClass:"px-3 py-6"},[(room.isLight)?_c('chip-light'):_vm._e()],1),_c('td',{staticClass:"px-3 py-6"},_vm._l((room.timeBlocks),function(timeBlock){return _c('chip-time-block',{key:timeBlock.id,attrs:{"start":timeBlock.startTime,"end":timeBlock.endTime}})}),1),_c('td',{staticClass:"py-6 pl-3 pr-6"},[_c('div',{},[_c('e-tooltip',{staticClass:"text-center",attrs:{"content":room.examSessions.length > 0
                ? 'Löschen nicht möglich (Bookings)'
                : 'Löschen',"class-definitions":"block"}},[_c('e-button',{attrs:{"disabled":room.examSessions.length > 0,"data-test":"room-row-delete","variant":"text","small":""},on:{"click":function($event){return _vm.deleteRoom(room)}}},[_c('e-icon',{attrs:{"color":"primary","icon":"mdi-trash-can-outline"}})],1)],1)],1)])])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }