import { BasicResourceFactory } from '@/api/ApiResourceFactory';

/**
 * @type {BasicApiResource}
 * @private
 */
const { updateById, deleteById } = BasicResourceFactory('addresses');

export const formatAddress = (address) => {
  return {
    name: address.name,
    street: address.street,
    houseNumber: address.houseNumber,
    city: address.city,
    zip: address.zip,
    countryId: address.countryId.countryIso,
    type: address.type,
  };
};

export const addresses = {
  update: (addressId, payload) => updateById(addressId, formatAddress(payload)),
  deleteById,
  setType: (contactPersonId, payload, type) =>
    updateById(contactPersonId, {
      ...formatAddress(payload),
      type,
    }),
};

/**
 * @typedef {Object} Address
 */
