<template>
  <room-form :room="room" @submit="updateRoom" />
</template>

<script>
import RoomForm from '@/components/exam-centers/RoomForm';
export default {
  name: 'RoomUpdate',
  components: { RoomForm },
  props: {
    room: {
      type: Object,
      required: true,
    },
    examCenterId: {
      type: String,
      required: true,
    },
  },
  methods: {
    updateRoom(room) {
      this.$emit('update-room', room);
    },
  },
};
</script>
