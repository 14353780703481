<template>
  <deletion-modal
    :is-open="isOpen"
    data-test="confirmation-modal"
    @close="close"
    @confirm="deleteRoom"
  >
    <p class="pb-6 font-bold">
      Bist Du sicher, dass Du den Raum löschen möchtest?
    </p>
    <p class="pb-6" data-test="modal-room-name">
      {{ room.name }}
    </p>
    <p class="pb-6 text-xs text-gray-600">
      Räume werden archiviert und können NICHT wiederhergestellt werden.
    </p>
  </deletion-modal>
</template>

<script>
import { Api } from '@/api';
import DeletionModal, {
  useDeletionModal,
} from '@/components/common/DeletionModal';

const DELETE_SUCCESS_MESSAGE = 'The room has been deleted';

export default {
  name: 'RoomDelete',
  components: { DeletionModal },
  props: {
    room: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { close, confirm } = useDeletionModal(
      DELETE_SUCCESS_MESSAGE,
      context
    );

    const deleteRoom = () => confirm(() => Api.rooms.deleteById(props.room.id));

    return {
      close,
      deleteRoom,
    };
  },
};
</script>
