var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('e-toolbar',[_c('e-button',{staticClass:"epos-button--small mr-4",attrs:{"to":{ name: 'create' },"data-test":"create-exam-center","small":"","type":"routerLink","variant":"tertiary"}},[_c('e-icon',{staticClass:"mr-2",attrs:{"icon":"mdi-plus"}}),_vm._v(" Prüfungszentrum hinzufügen ")],1),_c('e-menu',{attrs:{"id":"toolbar__menu-time-blocks","actions":_vm.timeBlocksActions,"data-test":"assign-time-blocks"},on:{"assign-time-block":_vm.bulkAssignTimeBlock},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var interact = ref.interact;
return [_c('e-button',{staticClass:"epos-button--small mr-4",attrs:{"id":"toolbar__menu-time-blocks__activator","disabled":!_vm.selected.length,"data-test":"assign-time-blocks-button","small":"","type":"button","variant":"tertiary"},nativeOn:{"click":function($event){$event.stopPropagation();return interact.apply(null, arguments)}}},[_c('e-icon',{staticClass:"mr-2",attrs:{"icon":"mdi-clock-plus-outline"}}),_vm._v(" Zeitblock zuordnen "),_c('e-icon',{attrs:{"icon":"mdi-menu-down"}})],1)]}}])})],1),_c('error-banner'),_c('section-structure',{attrs:{"grid":false}},[_c('form',{staticClass:"mb-10",on:{"submit":function($event){$event.preventDefault();return _vm.searchByName.apply(null, arguments)}}},[_c('div',{staticClass:"flex"},[_c('e-text-field',{staticClass:"flex-grow",attrs:{"id":"exam-centers__search-by-label","data-test":"search-by-label-input","label":"Suche nach Name des Prüfungszentrums oder des Raumes","rounded":"rounded-l"},model:{value:(_vm.searchByNameValue),callback:function ($$v) {_vm.searchByNameValue=$$v},expression:"searchByNameValue"}}),_c('e-button',{staticClass:"mt-px min-w-20 rounded-l-none",attrs:{"id":"exam-centers__search-submit","aria-label":"Suche","data-test":"search-submit","variant":"primary","action-type":"submit"}},[_c('e-icon',{attrs:{"icon":"mdi-magnify"}})],1)],1)]),(_vm.activeFilters.length)?_c('filter-toolbar',{attrs:{"filters":_vm.activeFilters},on:{"reset":_vm.resetFilters}}):_vm._e(),_c('e-checkbox-group',{attrs:{"items":_vm.rooms},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
var toggleAll = ref.toggleAll;
var allSelected = ref.allSelected;
return [_c('div',[_c('div',{staticClass:"col-span-full mb-7 flex justify-between"},[_c('e-checkbox',{staticClass:"pl-4",attrs:{"id":"exam-centers__checkbox--select-all","options":allSelected,"check-value":"select-all","data-test":"checkbox-select-all","label":"Alle auswählen"},on:{"change":toggleAll}}),_c('p',{staticClass:"text-gray-700"},[_vm._v(" Anzeige aller "),_c('span',{staticClass:"font-bold text-primary"},[_vm._v(_vm._s(_vm.pagination.totalCount))]),_vm._v(" Prüfungszentren ")])],1),_c('e-simple-table',{attrs:{"header-items":_vm.headerItems,"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"th-name",fn:function(){return [_c('span',{staticClass:"uppercase"},[_vm._v(" Name "),_c('e-button',{attrs:{"data-test":"table-sort-by-name","small":"","variant":"text"},on:{"click":_vm.sortByName}},[_c('e-icon',{attrs:{"icon":_vm.sort === ''
                        ? 'sort-alphabetical-ascending'
                        : 'sort-alphabetical-descending',"color":"primary","size":"sm"}})],1)],1)]},proxy:true},{key:"th-light",fn:function(ref){
                        var item = ref.item;
return [_c('e-simple-table-filter',{attrs:{"id":"exam-centers__filter-light","label":item.label,"options":_vm.filtersByIsLightOptions,"value":_vm.$route.query.isLight || '',"data-test":"table-filter-by-is-light"},on:{"input":_vm.filterByIsLight}})]}},{key:"th-time-block",fn:function(ref){
                        var item = ref.item;
return [_c('e-simple-table-filter',{attrs:{"id":"exam-centers__filter-timeblock","label":item.label,"options":_vm.filtersByTimeBlocksOptions,"value":_vm.$route.query.timeBlocks || '',"data-test":"table-filter-by-timeblocks"},on:{"input":_vm.filterByTimeBlocks}})]}},{key:"no-results",fn:function(){return [(!_vm.examCenters.length)?_c('div',{staticClass:"my-8 mx-auto flex max-w-sm flex-col items-center text-center",attrs:{"data-test":"no-results"}},[_c('img',{attrs:{"src":_vm.imgNoSearchResults,"alt":"","aria-hidden":"true","height":"160"}}),_c('p',{staticClass:"mt-6 font-bold"},[_vm._v("Keine Prüfungszentren gefunden.")]),_c('e-button',{staticClass:"mt-6",attrs:{"data-test":"reset-filters","variant":"primary"},on:{"click":function () { return _vm.resetFilters(); }}},[_vm._v(" Alle verfügbaren Prüfungszentren anzeigen ")])],1):_vm._e()]},proxy:true}],null,true)},_vm._l((_vm.examCenters),function(examCenter){return _c('row-group',{key:examCenter.id,attrs:{"exam-center":examCenter,"selected":_vm.selected},on:{"select":toggle,"delete-exam-center":_vm.goToExamCenterDelete,"delete-room":_vm.goToRoomDelete}})}),1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"mt-16 flex justify-end"},[_c('e-pagination',{attrs:{"length":_vm.pagesLength,"value":_vm.page},on:{"input":_vm.changePage}})],1)],1),_c('router-view',{attrs:{"is-open":_vm.$route.meta.showDeleteModal,"name":"deletion-modal"},on:{"close":_vm.closeModal,"delete-success":_vm.getExamCenters}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }