<template>
  <div class="flex flex-col items-center">
    <div class="w-full">
      <e-calendar
        :highlighted-dates="bookedDates"
        :to-page="toPage"
        :value="selectedDateString"
        class="my-9 w-full"
        @input="onCalendarClick"
        @update:to-page="updateToPage"
      />
    </div>
    <div class="flex w-full flex-col rounded-lg bg-blue-100">
      <div class="flex items-center border-b border-gray-400 p-5">
        <div class="font-bold text-black">
          <p>
            Räume buchen für Prüfung am
            {{ formatDateToLocale(selectedDateString) }}
          </p>
        </div>
      </div>
      <div class="flex flex-col p-5 pb-0">
        <e-checkbox-group v-model="selected" :items="roomIds">
          <template #default="{ toggle: onToggle, toggleAll }">
            <e-dual-picker
              :items="filteredRooms"
              :items-search-selected="searchSelected"
              :items-search-unselected="searchItems"
              :selected-filtered="selectedFilteredRooms"
              @toggle="(value) => onToggle([value])"
              @toggle-all="toggleAll"
              @update:items-search-unselected="setSearchUnselected"
              @update:items-search-selected="setSearchSelected"
            >
              <template #headlineLeft><p>Alle verfügbaren Räume</p></template>
              <template #headlineRight><p>Ausgewählte Räume</p></template>
              <template #emptySelected>
                <p class="px-3 py-2 font-bold">Kein Raum zugeordnet</p>
              </template>
              <template #emptyAvailable>
                <p class="px-3 py-2 font-bold">Keine Räume verfügbar</p>
              </template>
              <template #unselected="{ items, toggle }">
                <li v-for="examCenter in items" :key="examCenter.id">
                  <p class="py-2 pl-4 font-bold">{{ examCenter.name }}</p>
                  <ul>
                    <e-dual-picker-list-item
                      v-for="room in examCenter.rooms"
                      :key="room.id"
                      @click.native="toggle(room.id)"
                    >
                      {{ room.name }}
                    </e-dual-picker-list-item>
                  </ul>
                </li>
              </template>
              <template #selected="{ selectedItems, toggle }">
                <li v-for="group in selectedItems" :key="group.name">
                  <p class="py-2 pl-4 font-bold">{{ group.name }}</p>
                  <ul>
                    <e-dual-picker-list-item
                      v-for="room in group.rooms"
                      :key="room.id"
                      action="remove"
                      @click.native="toggle(room.id)"
                    >
                      {{ room.name }}
                    </e-dual-picker-list-item>
                  </ul>
                </li>
              </template>
            </e-dual-picker>
          </template>
        </e-checkbox-group>
      </div>
      <div class="flex justify-end p-5">
        <e-button
          :disabled="isLoading"
          class="mt-18 ml-auto"
          variant="primary"
          data-test="calendar-save"
          @click="submit"
        >
          <e-icon
            v-if="isLoading"
            class="mr-2 animate-spin"
            icon="mdi-loading"
          />
          Speichern
        </e-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from '@/api';
import { updateSnackbar } from '@/store/modules/snackbar';
import { formatDateToLocale, getDateISOString } from '@/utils/date';
import { getIds } from '@/utils/formatters';

const match = (label, searchString) =>
  label.toLowerCase().includes(searchString.toLowerCase());

export default {
  name: 'Calendar',
  props: {
    examCenters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      searchSelected: '',
      searchItems: '',
      examSessions: [],
      selected: [],
      selectedDateString: '',
      from: new Date(),
      until: new Date(),
    };
  },
  computed: {
    bookedDates() {
      return this.examSessions.map((examSession) => new Date(examSession.date));
    },
    roomIds() {
      const rooms = this.examCenters.flatMap(({ rooms }) => rooms);
      return getIds(rooms);
    },
    filteredRooms() {
      return this.examCenters
        .map(({ rooms, ...examCenter }) => ({
          ...examCenter,
          rooms: rooms.filter(
            ({ id, label }) =>
              !this.selected.includes(id) && match(label, this.searchItems)
          ),
        }))
        .filter(({ rooms }) => rooms.length);
    },
    selectedFilteredRooms() {
      return this.examCenters
        .map(({ rooms, ...examCenter }) => ({
          ...examCenter,
          rooms: rooms.filter(
            ({ id, label }) =>
              this.selected.includes(id) && match(label, this.searchSelected)
          ),
        }))
        .filter(({ rooms }) => rooms.length);
    },
    toPage() {
      return {
        month: this.until.getMonth() + 1,
        year: this.until.getFullYear(),
      };
    },
  },
  async created() {
    const selectedDateString = this.$route.query.selected;
    this.selectedDateString = selectedDateString?.match(/^\d{4}-\d{2}-\d{2}$/)
      ? selectedDateString
      : getDateISOString();

    const until = this.$route.query.until || '';
    const yearString = until.substring(0, 4);
    const monthString = until.substring(5, 7);
    const isDateValid =
      until?.match(/^\d{4}-\d{2}$/) &&
      this.validDate({ year: yearString, month: monthString });

    await this.updateToPage({
      year: isDateValid ? yearString : new Date().getFullYear(),
      month: isDateValid ? monthString : new Date().getMonth() + 3,
    });
    await this.onCalendarClick(this.selectedDateString);
  },
  methods: {
    formatDateToLocale,
    validDate(date) {
      const year = Number.parseInt(date.year);
      const month = Number.parseInt(date.month);

      const isInteger = Number.isInteger(year) || Number.isInteger(month);
      const yearGreaterThan2000 = year > 2000;
      const yearSmallerThan3000 = year < 3000;
      const monthGreaterThan0 = month > 0;
      const monthSmallerThan13 = month < 13;

      return (
        isInteger &&
        yearGreaterThan2000 &&
        yearSmallerThan3000 &&
        monthGreaterThan0 &&
        monthSmallerThan13
      );
    },
    getRoomIdsByDateString(dateString) {
      return this.examSessions
        .filter(({ date }) => date === dateString)
        .map(({ roomId }) => roomId);
    },
    setSearchUnselected(value) {
      this.searchItems = value.trim();
    },
    setSearchSelected(value) {
      this.searchSelected = value.trim();
    },
    async getExamSessionsRange(from, until) {
      try {
        this.isLoading = true;

        let examCenterId;
        if (this.examCenters.length === 1) {
          examCenterId = this.examCenters[0].id;
        }
        const { data } = await Api.examSessions.get(from, until, examCenterId);

        this.examSessions = data;
      } finally {
        this.isLoading = false;
      }
    },
    async updateToPage(val) {
      if (
        val.month !== this.until.getMonth() ||
        val.year !== this.until.getFullYear()
      ) {
        this.from = new Date(val.year, val.month - 3, 1);
        this.until = new Date(val.year, val.month, 0);

        this.$router.push({
          ...this.$route,
          query: {
            until: getDateISOString(this.until).substring(0, 7),
            selected: this.selectedDateString,
          },
        });

        await this.getExamSessionsRange(this.from, this.until);
      }
    },
    async onCalendarClick(date) {
      this.selectedDateString = date ? date : getDateISOString();
      this.selected = this.getRoomIdsByDateString(this.selectedDateString);

      this.$router.push({
        ...this.$route,
        query: {
          until: getDateISOString(this.until).substring(0, 7),
          selected: this.selectedDateString,
        },
      });
    },
    async submit() {
      try {
        const initialValue = this.getRoomIdsByDateString(
          this.selectedDateString
        );

        const roomIdsToCreateExamSession = this.selected.filter(
          (roomId) => !initialValue.includes(roomId)
        );

        const roomIdsToRemoveExamSession = initialValue.filter(
          (roomId) => !this.selected.includes(roomId)
        );

        const examSessionIdsToDelete = this.examSessions
          .filter((examSession) => {
            return roomIdsToRemoveExamSession.find((roomId) => {
              return (
                roomId === examSession.roomId &&
                this.selectedDateString === examSession.date
              );
            });
          })
          .map(({ id }) => id);

        await Promise.all([
          ...roomIdsToCreateExamSession.map((roomId) =>
            Api.examSessions.create(this.selectedDateString, roomId)
          ),
        ]);

        let errorWhileDeletion = false;
        for (const examSessionId of examSessionIdsToDelete) {
          try {
            await Api.examSessions.deleteById(examSessionId);
          } catch (error) {
            console.log(`Deletion of exam session failed: ${error.message}`);
            errorWhileDeletion = true;
          }
        }

        if (!errorWhileDeletion) {
          updateSnackbar({ message: 'Exam events have been updated' });
        } else {
          updateSnackbar({
            message:
              'Eine oder mehrere Raumzuordnungen konnten nicht entfernt werden',
            error: true,
          });
        }

        await this.refresh();
      } finally {
        this.isLoading = false;
      }
    },
    async refresh() {
      this.$emit('refresh');

      await this.getExamSessionsRange(this.from, this.until);
    },
  },
};
</script>
