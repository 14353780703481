<template>
  <form
    class="epos__room-form col-span-12 mb-16"
    data-test="contact-person-form"
  >
    <validation-observer v-slot="{ invalid }" ref="observer" slim>
      <div class="flex flex-col gap-x-6 lg:grid lg:grid-cols-12">
        <div class="col-span-6 my-3 mt-6">
          <validation-provider
            v-slot="{ errors }"
            name="Vorname"
            rules="required|max:100"
            slim
          >
            <e-text-field
              id="contact-person-form__givenName"
              v-model.trim="value.givenName"
              data-test="contact-person-form__givenName"
              :errors="errors"
              :autofocus="contactPerson.id === null"
              label="Vorname"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-6 my-3 mt-6">
          <validation-provider
            v-slot="{ errors }"
            name="Nachname"
            rules="required|max:50"
            slim
          >
            <e-text-field
              id="contact-person-form__familyName"
              v-model.trim="value.familyName"
              data-test="contact-person-form__familyName"
              :errors="errors"
              label="Nachname"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-12 my-3">
          <validation-provider
            v-slot="{ errors }"
            name="E-Mail-Adresse"
            rules="required|email"
            slim
          >
            <e-text-field
              id="contact-person-form__email"
              v-model.trim="value.email"
              data-test="contact-person-form__email"
              :errors="errors"
              label="Email"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-12 my-3">
          <validation-provider
            v-slot="{ errors }"
            name="Telefonnummer"
            rules="required|phone"
            slim
          >
            <e-text-field
              id="contact-person-form__phone"
              v-model.trim="value.phone"
              data-test="contact-person-form__phone"
              :errors="errors"
              label="Telefonnummer"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-12 my-3">
          <p class="text-xs text-gray-700">* Pflichtfelder</p>
        </div>
      </div>
      <portal to="form-modal-submit">
        <e-button
          variant="primary"
          :disabled="invalid || !hasChanged"
          class="mt-18 ml-auto"
          data-test="modal-confirm"
          @click="$emit('submit', value)"
        >
          Speichern
        </e-button>
      </portal>
    </validation-observer>
  </form>
</template>

<script>
import _ from '@/plugins/lodash';

export default {
  name: 'ContactPersonForm',
  props: {
    contactPerson: {
      type: Object,
      default: () => ({
        id: null,
        givenName: '',
      }),
    },
  },
  data() {
    return {
      value: this.contactPerson,
    };
  },
  computed: {
    hasChanged() {
      return !_.isEqual(this.value, this.initialValue);
    },
    initialValue() {
      return _.pick(this.contactPerson, Object.keys(this.value));
    },
  },
  mounted() {
    this.value = this.initialValue;
  },
};
</script>
