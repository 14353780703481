<template>
  <div>
    <portal to="view-subheadline">
      {{ subheadline }}
    </portal>
    <router-view @set-view-subheadline="setViewSubheadline" />
  </div>
</template>

<script>
export default {
  name: 'ExamCenterUpdate',
  data() {
    return {
      subheadline: '',
    };
  },
  methods: {
    setViewSubheadline(name) {
      this.subheadline = name;
    },
  },
};
</script>
