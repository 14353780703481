<template>
  <deletion-modal
    :is-open="isOpen"
    data-test="confirmation-modal"
    @close="close"
    @confirm="deleteExamCenter"
  >
    <p class="pb-6 font-bold">
      Bist Du sicher, dass Du das Prüfungszentrum löschen möchtest?
    </p>
    <p class="pb-6" data-test="modal-exam-center-name">
      {{ examCenter.name }}
    </p>
    <p class="pb-6 text-xs text-gray-600">
      Prüfungzentren werden archiviert und können NICHT wiederhergestellt
      werden.
    </p>
  </deletion-modal>
</template>

<script>
import { Api } from '@/api';
import DeletionModal, {
  useDeletionModal,
} from '@/components/common/DeletionModal';

const DELETE_SUCCESS_MESSAGE = 'The Exam Center has been deleted';

export default {
  name: 'ExamCenterDelete',
  components: { DeletionModal },
  props: {
    examCenter: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { close, confirm } = useDeletionModal(
      DELETE_SUCCESS_MESSAGE,
      context
    );

    const deleteExamCenter = () =>
      confirm(() => Api.examCenters.deleteById(props.examCenter.id));

    return {
      close,
      deleteExamCenter,
    };
  },
};
</script>
