<template>
  <div>
    <contacts-box
      :contacts="contactPersons"
      :actions="actions"
      :tags="tags"
      section-title="Ansprechpartner"
      data-test="exam-center-contact-persons"
      @click-update="clickUpdate"
      @click-delete="clickDelete"
      @click-set-as-primary="setAsPrimary"
    >
      <template #data="{ item: contactPerson }">
        <span class="truncate">
          {{ contactPerson.givenName }} {{ contactPerson.familyName }}<br />
          {{ contactPerson.phone }}<br />
          {{ contactPerson.email }}
        </span>
      </template>
    </contacts-box>
    <e-button
      variant="text"
      class="mt-4"
      data-test="exam-center-add-contact"
      @click="clickAdd"
    >
      <e-icon icon="mdi-plus" />
      Kontaktperson hinzufügen
    </e-button>
  </div>
</template>
<script>
import ContactsBox from '@/components/common/ContactsBox';
export default {
  name: 'ContactPersonsBox',
  components: { ContactsBox },
  props: {
    contactPersons: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    tags(contactPerson) {
      return contactPerson.primary ? ['Hauptansprechpartner'] : [];
    },
    actions(contactPerson) {
      return [
        {
          id: 'click-update',
          text: 'bearbeiten',
          disabled: false,
        },
        {
          id: 'click-delete',
          text: 'löschen',
          disabled: contactPerson.primary,
        },
        {
          id: 'click-set-as-primary',
          text: 'als Hauptansprechpartner festlegen',
          disabled: contactPerson.primary,
        },
      ];
    },
    clickAdd() {
      this.$emit('open-contact-person-create');
    },
    clickUpdate(payload) {
      this.$emit('open-contact-person-update', payload);
    },
    clickDelete(payload) {
      this.$emit('open-contact-person-delete', payload);
    },
    setAsPrimary(payload) {
      this.$emit('set-as-primary-contact', payload);
    },
  },
};
</script>
