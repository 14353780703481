<template>
  <e-modal
    :is-open="isOpen"
    size="sm"
    :display-close-button="false"
    blue-50
    auto-height
    @close="$emit('close')"
  >
    <div class="px-7 py-6">
      <div class="modal-content">
        <div class="col-span-full">
          <img :src="imageUrl" alt="" class="mx-auto mb-6 h-24" />
        </div>
        <slot />
      </div>
      <div class="flex h-9 justify-between">
        <e-button
          variant="secondary"
          data-test="modal-cancel"
          @click="$emit('close')"
        >
          Abbrechen
        </e-button>
        <e-button
          variant="primary"
          data-test="modal-confirm"
          @click="$emit('confirm')"
        >
          Löschen
        </e-button>
      </div>
    </div>
  </e-modal>
</template>

<script>
import { IMAGE_URLS } from '@/constants';

export default {
  name: 'DeletionModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageUrl() {
      return IMAGE_URLS.OVERLAYS.DELETE;
    },
  },
};
</script>

<style scoped>
.modal-content {
  @apply overflow-y-auto text-center;
  max-height: 60vh;
}
</style>
