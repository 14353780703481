<template>
  <form class="epos__room-form col-span-12 mb-16" data-test="room-form">
    <validation-observer ref="observer" v-slot="{ invalid }" slim>
      <div class="flex flex-col gap-x-6 lg:grid lg:grid-cols-12">
        <div class="col-span-8 my-3 mt-6">
          <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required"
            slim
          >
            <e-text-field
              id="room-form__name"
              v-model.trim="value.name"
              data-test="room-form__name"
              :autofocus="room.id === null"
              :errors="errors"
              label="Name"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-4 my-3 mt-6">
          <validation-provider
            v-slot="{ errors }"
            name="Kapazität"
            rules="required"
            slim
          >
            <e-text-field
              id="room-form__capacity"
              v-model.trim="value.capacity"
              data-test="room-form__capacity"
              :errors="errors"
              label="Kapazität"
              required
              type="number"
            />
          </validation-provider>
        </div>
        <div class="col-span-12 my-3">
          <validation-provider v-slot="{ errors }" name="Timeblocks" slim>
            <e-multiselect
              id="room-form__timeblocks"
              v-model="timeBlocks"
              data-test="room-form__timeblocks"
              :errors="errors"
              :multiple="true"
              :options="getTimeBlocksAsOptions"
              label="Zeitblock (Mehrfachauswahl möglich)"
              option-label="text"
              track-by="id"
            />
          </validation-provider>
        </div>
        <div class="col-span-12 my-3">
          <validation-provider v-slot="{ errors }" name="isLight" slim>
            <e-checkbox
              id="room-form__isLight"
              v-model.trim="value.isLight"
              data-test="room-form__isLight"
              :errors="errors"
              check-value="isLight"
              label="Light"
            />
          </validation-provider>
        </div>
        <div class="col-span-12 my-3">
          <p class="text-xs text-gray-700">* Pflichtfelder</p>
        </div>
      </div>
      <portal to="form-modal-submit">
        <e-button
          slot-scope="slotProps"
          :disabled="invalid || !hasChanged || slotProps.isLoading"
          class="mt-18 ml-auto"
          data-test="modal-confirm"
          variant="primary"
          @click="$emit('submit', value)"
        >
          <e-icon
            v-if="!!slotProps.isLoading"
            class="mr-2 animate-spin"
            icon="mdi-loading"
          />
          Speichern
        </e-button>
      </portal>
    </validation-observer>
  </form>
</template>

<script>
import _ from '@/plugins/lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'RoomForm',
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: { ...this.room },
    };
  },
  computed: {
    ...mapGetters({
      getTimeBlocksAsOptions: 'timeBlocks/getTimeBlocksAsOptions',
    }),
    timeBlocks: {
      get() {
        return this.value.timeBlocks.map(({ id }) =>
          this.getTimeBlocksAsOptions.find((item) => id === item.id)
        );
      },
      set(value) {
        this.value.timeBlocks = value;
      },
    },
    hasChanged() {
      return !_.isEqual(this.value, this.initialValue);
    },
    initialValue() {
      return _.pick(this.room, Object.keys(this.value));
    },
  },
  async mounted() {
    this.value = this.initialValue;
  },
};
</script>
