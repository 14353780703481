<template>
  <section-structure>
    <template #headline> Kontaktdaten </template>
    <div class="flex flex-col gap-x-6 lg:grid lg:grid-cols-12">
      <address-box
        class="col-span-6 mb-14"
        :addresses="examCenter.addresses"
        @open-address-create="goToAddressCreate"
        @open-address-update="goToAddressUpdate"
        @open-address-delete="goToAddressDelete"
        @set-as-main-address="setAddressMain"
        @set-as-correspondence-address="setAddressCorrespondence"
      />
      <contact-persons-box
        class="col-span-6 mb-14"
        :contact-persons="examCenter.contactPersons"
        @open-contact-person-create="goToPersonCreate"
        @open-contact-person-update="goToPersonUpdate"
        @open-contact-person-delete="goToPersonDelete"
        @set-as-primary-contact="setAsPrimary"
      />
    </div>
  </section-structure>
</template>

<script>
import SectionStructure from '@/components/common/SectionStructure';
import AddressBox from '@/components/common/AddressBox';
import ContactPersonsBox from '@/components/common/ContactPersonsBox';

export default {
  name: 'ExamCenterContactData',
  components: { ContactPersonsBox, AddressBox, SectionStructure },
  props: {
    examCenter: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToPersonCreate() {
      this.$router.push({
        name: 'exam-center-contact-persons-create',
        params: {
          examCenterId: this.examCenter.id,
          examCenter: this.examCenter,
        },
      });
    },
    goToPersonUpdate(contactPerson) {
      this.$router.push({
        name: 'exam-center-contact-persons-update',
        params: {
          examCenterId: this.examCenter.id,
          examCenter: this.examCenter,
          contactPersonId: contactPerson.id,
          contactPerson,
        },
      });
    },
    goToPersonDelete(contactPerson) {
      this.$router.push({
        name: 'exam-center-contact-persons-delete',
        params: {
          examCenterId: this.examCenter.id,
          examCenter: this.examCenter,
          contactPersonId: contactPerson.id,
          contactPerson,
        },
      });
    },
    goToAddressCreate() {
      this.$router.push({
        name: 'exam-center-addresses-create',
        params: {
          examCenter: this.examCenter,
          examCenterId: this.examCenter.id,
        },
      });
    },
    goToAddressUpdate(address) {
      this.$router.push({
        name: 'exam-center-addresses-update',
        params: {
          examCenterId: this.examCenter.id,
          examCenter: this.examCenter,
          addressId: address.id,
          address,
        },
      });
    },
    goToAddressDelete(address) {
      this.$router.push({
        name: 'exam-center-addresses-delete',
        params: {
          examCenterId: this.examCenter.id,
          examCenter: this.examCenter,
          addressId: address.id,
          address,
        },
      });
    },
    setAsPrimary(contact) {
      this.$emit('set-contact-primary', contact);
    },
    setAddressMain(address) {
      this.$emit('set-address-type', address, 'main');
    },
    setAddressCorrespondence(address) {
      this.$emit('set-address-type', address, 'correspondence');
    },
  },
};
</script>
