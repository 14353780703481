<template>
  <e-applet-wrapper
    id="epos-exam-center"
    name="EPOS Exam Center"
    data-test="exam-center-view"
  >
    <template #viewnavigation>
      <e-view-navigation
        :links="$route.meta.links"
        data-test="exam-center-view-navigation"
      >
        <template #headline>
          <span data-test="exam-center-view-headline">{{
            $route.meta.headline
          }}</span>
        </template>
        <template #subheadline>
          <span data-test="exam-center-view-subheadline">
            {{ $route.meta.subheadline }}
            <portal-target
              v-if="!$route.meta.subheadline"
              name="view-subheadline"
            />
          </span>
        </template>
      </e-view-navigation>
    </template>
    <template #appletcontent>
      <router-view />
      <snackbar data-test="exam-center-view-snackbar" />
    </template>
  </e-applet-wrapper>
</template>

<script>
import { mapActions } from 'vuex';
import { EAppletWrapper, EViewNavigation } from '@careerpartner/nitro';
import { Api } from '@/api';
import Snackbar from '@/components/common/Snackbar';

export default {
  name: 'App',
  components: { EAppletWrapper, EViewNavigation, Snackbar },
  created() {
    this.fetchTimeBlocks();
    this.setCurrentUser();
  },
  methods: {
    ...mapActions({
      setTimeBlocks: 'timeBlocks/setTimeBlocks',
      setCurrentUser: 'currentUser/setCurrentUser',
    }),
    async fetchTimeBlocks() {
      const { data } = await Api.timeBlocks.getAll();
      await this.setTimeBlocks(data);
    },
  },
};
</script>
