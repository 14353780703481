<template>
  <section-structure>
    <template #headline> Allgemeine Informationen </template>
    <div class="col-span-12 my-6">
      <validation-provider
        v-slot="{ errors }"
        name="Bezeichnung"
        rules="required|max:255"
        slim
      >
        <e-text-field
          id="exam-center-form__name"
          v-model.trim="examCenter.name"
          :errors="errors"
          label="Bezeichung des Prüfungszentrums"
          data-test="exam-center-form__name"
          required
        />
      </validation-provider>
    </div>
    <div class="col-span-12 my-6">
      <validation-provider
        v-slot="{ errors }"
        name="Ort"
        rules="required|max:255"
        slim
      >
        <e-text-field
          id="exam-center-form__location"
          v-model.trim="examCenter.location"
          data-test="exam-center-form__location"
          :errors="errors"
          label="Ort"
          required
        />
      </validation-provider>
    </div>
    <div class="col-span-12 mt-6 mb-3">
      <validation-provider
        v-slot="{ errors }"
        name="Anfahrtsbeschreibung"
        rules="max:255"
        slim
      >
        <e-text-area
          id="exam-center-form__directions"
          v-model.trim="examCenter.directions"
          label="Anfahrtsbeschreibung"
          data-test="exam-center-form__directions"
          :textarea-classes="['h-24']"
          :resize="false"
          :errors="errors"
          :max-length="255"
          description="Maximal 255 Zeichen."
        />
      </validation-provider>
    </div>
    <div class="col-span-12 mt-3">
      <validation-provider
        v-slot="{ errors }"
        name="Kommentar"
        rules="max:255"
        slim
      >
        <e-text-area
          id="exam-center-form__comment"
          v-model.trim="examCenter.comment"
          label="Kommentar"
          data-test="exam-center-form__comment"
          :textarea-classes="['h-24']"
          :resize="false"
          :errors="errors"
          :max-length="255"
          description="Maximal 255 Zeichen."
        />
      </validation-provider>
    </div>
  </section-structure>
</template>

<script>
import SectionStructure from '@/components/common/SectionStructure';

export default {
  name: 'General',
  components: { SectionStructure },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    examCenter() {
      return this.value;
    },
  },
};
</script>
