<template>
  <e-chip class="ml-2 mb-1 align-middle" :class="bgColor">
    {{ start }} - {{ end }}
  </e-chip>
</template>

<script>
export default {
  name: 'ChipTimeBlock',
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
  },
  computed: {
    bgColor() {
      const start = parseInt(this.start.split(':')[0]);
      return (
        (start < 12 && 'bg-aqua-darker') ||
        (start < 17 && 'bg-gray-500') ||
        'bg-flamingo-lighter'
      );
    },
  },
};
</script>
