<template>
  <contact-person-form @submit="createContactPerson" />
</template>

<script>
import ContactPersonForm from '@/components/exam-centers/ContactPersonForm';

export default {
  name: 'ContactPersonCreate',
  components: { ContactPersonForm },
  props: {
    examCenterId: {
      type: String,
      required: true,
    },
  },
  methods: {
    createContactPerson(contactPerson) {
      this.$emit('create-contact-person', contactPerson);
    },
  },
};
</script>
