<template>
  <calendar :exam-centers="examCenters" @refresh="getExamCenters" />
</template>

<script>
import { Api } from '@/api';
import Calendar from '@/components/exam-centers/Calendar';

export default {
  name: 'ExamCentersCalendar',
  components: { Calendar },
  data() {
    return {
      examCenters: [],
    };
  },
  async mounted() {
    await this.getExamCenters();
  },
  methods: {
    async getExamCenters() {
      const { data } = await Api.examCenters.get({ limit: 1000 });
      this.examCenters = data.data.filter(
        (examCenter) => examCenter.rooms.length
      );
    },
  },
};
</script>
