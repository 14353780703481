import { addresses } from '@/api/Addresses';
import { contactPersons } from '@/api/ContactPersons';
import { examCenters } from '@/api/ExamCenters';
import { examSessions } from '@/api/ExamSessions';
import { rooms } from '@/api/Rooms';
import { timeBlocks } from '@/api/TimeBlocks';

/**
 * @type {Readonly<{}>}
 */
export const Api = Object.freeze({
  examCenters,
  rooms,
  contactPersons,
  addresses,
  timeBlocks,
  examSessions,
});
