import { BasicResourceFactory } from '@/api/ApiResourceFactory';
import { formatRoom } from '@/api/Rooms';
import { formatAddress } from '@/api/Addresses';

/**
 * @type {BasicApiResource}
 * @private
 */
const { deleteById, get, getById, post, postSubPathById, updateById } =
  BasicResourceFactory('exam-centers');

/**
 * @param {number} limit
 * @param {number} offset
 * @param {Date} date
 * @param {String} isLight
 * @param {String} name
 * @param {String} location
 * @param {String} timeBlocks
 * @returns {{'filter[date]': *, offset, 'filter[isLight]': *, limit, 'filter[timeBlocks]': *, 'filter[name]': *, 'filter[location]': *}}
 */
export const formatSearchQuery = ({
  date,
  isLight,
  name,
  location,
  timeBlocks,
  limit,
  offset,
  sort,
}) => ({
  ...(name ? { 'filter[name]': name } : {}),
  ...(date ? { 'filter[date]': date } : {}),
  ...(isLight && (isLight === 'true' || isLight === 'false')
    ? { 'filter[isLight]': isLight }
    : {}),
  ...(location ? { 'filter[location]': location } : {}),
  ...(timeBlocks ? { 'filter[timeBlocks]': timeBlocks } : {}),
  ...(sort ? { sort } : {}),
  limit: limit || 10,
  offset: offset || 0,
});

/**
 * @param payload
 * @returns ExamCenter
 */
const formatExamCenter = ({ ...payload }) => payload;
const formatContactPerson = (contactPerson) => {
  return {
    givenName: contactPerson.givenName,
    familyName: contactPerson.familyName,
    email: contactPerson.email,
    phone: contactPerson.phone,
    primary: false,
  };
};

export const examCenters = {
  deleteById,
  get: (search) => get(formatSearchQuery(search)),
  getById,
  post: (payload) => post(formatExamCenter(payload)),
  updateById: (examCenterId, payload) =>
    updateById(examCenterId, formatExamCenter(payload)),
  addresses: (examCenterId, payload) =>
    postSubPathById(examCenterId, 'addresses', formatAddress(payload)),
  rooms: (examCenterId, payload) =>
    postSubPathById(examCenterId, 'rooms', formatRoom(payload)),
  contactPersons: (examCenterId, payload) =>
    postSubPathById(
      examCenterId,
      'contact-persons',
      formatContactPerson(payload)
    ),
};

/**
 * @typedef {Object} ExamCenter
 * @property {String} id
 * @property {String} name
 * @property {String} location
 * @property {String} directions
 * @property {String} comment
 * @property {Object} addresses
 * @property {Array} [rooms]
 * @property {Array} [contactPersons]
 */

/**
 * @typedef {Object} Address
 */

/**
 * @typedef {Object} Room
 */

/**
 * @typedef {Object} ContactPerson
 */
