<template>
  <div class="mb-4 flex justify-between bg-blue-100 p-3">
    <span>
      <span class="mr-5 font-bold text-gray-700">Filter</span>
      <span
        v-for="filter in filters"
        :key="filter.id"
        class="mx-2 bg-cta px-2 text-white"
      >
        <span class="font-bold">{{ filter.type }}:</span> {{ filter.value }}
        <e-button variant="blank" @click="$emit('reset', filter.name)">
          <e-icon icon="mdi-close-box" size="s" />
        </e-button>
      </span>
    </span>
    <e-button variant="text" @click="$emit('reset')">
      Filter zurücksetzen
    </e-button>
  </div>
</template>

<script>
export default {
  name: 'FilterToolbar',
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
};
</script>
